import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertsBannerComponent } from '@ic/component-lib/src/components/modules/alerts-banner/alerts-banner.component';
import { MessageModel } from 'components/common/interfaces/message.model';
import { NotificationBannerService } from 'components/common/services/notification-banner/notification-banner.service';

@Component({
  selector: 'ic-notification-banner',
  templateUrl: './notification-banner.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NotificationBannerComponent implements OnInit, OnDestroy {

  @ViewChild(AlertsBannerComponent, { static: true }) alertsBanner!: AlertsBannerComponent;

  public message: MessageModel = <MessageModel>{};

  private notificationServiceSubscriber!: Subscription;

  constructor(private notificationService: NotificationBannerService) {}

  ngOnInit() {
    this.notificationServiceSubscriber = this.notificationService.messages
      .subscribe((message: MessageModel) => this.showMessage(message));
  }

  ngOnDestroy() {
    if (this.notificationServiceSubscriber) {
      this.notificationServiceSubscriber.unsubscribe();
    }
  }

  private showMessage(message: MessageModel) {
    this.message = message;
    this.alertsBanner.showBanner();

    if (message.duration > 0) {
      setTimeout(() => this.closeMessage(), message.duration);
    }
  }

  private closeMessage() {
    this.alertsBanner.closeBanner();
  }
}
