import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AnalyticsModule } from '../analytics/analytics.module';
import { AuthModule } from './../auth/auth.module';
import { CommonModule } from '../common/common.module';
import { DiagramModule } from '../diagram/diagram.module';
import { NavigationModule } from '../navigation/navigation.module';
import { RestModule } from '../rest/rest.module';
import { ViewModelsModule } from '../view-models/view-models.module';
import { UiModule } from '../ui/ui.module';
import { TranslationsModule } from '../translations/translations.module';
import { localeInitializerFactory } from './factories/locale/locale.factory';
import { translateInitializerFactory, translateHttpLoaderFactory } from './factories/translate/translate.factory';
import { MainContainerService } from './services/main-container/main-container.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LocaleInterceptor } from './interceptors/locale.interceptor';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { VersionInterceptor } from './interceptors/version.interceptor';
import { VersionRepositoryService } from './services/version-repository/version-repository.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OverbarModule } from '../../components/overbar/overbar.module';
import { PendoService } from './services/pendo/pendo.service';
import { SnackBarModule } from '../../components/snackbar/snackbar.module';
import { TabReportService } from 'pages/tab-report/services/tab-report/tab-report.service';
import {AppRoutingModule} from 'components/app/app-route';
import {AppComponent} from 'components/app/app.component';
import {WindowRefService} from 'components/common/window/window-ref.service';

@NgModule({
  imports: [
    AnalyticsModule,
    AuthModule,
    BrowserModule,
    CommonModule,
    DiagramModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    NavigationModule,
    OverbarModule.forRoot(),
    RestModule,
    SnackBarModule.forRoot(),
    ViewModelsModule,
    TranslationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    UiModule,
    AppRoutingModule
  ],
  providers: [
    { provide: APP_INITIALIZER,
      useFactory: translateInitializerFactory,
      deps: [TranslateService, Injector, WindowRefService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    MainContainerService,
    { provide: LOCALE_ID, useValue: localeInitializerFactory() },
    VersionRepositoryService,
    PendoService,
    Title,
    TabReportService
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})

export class AppModule {
}