import { AnalysisAppliedFiltersModel } from 'pages/analysis/models';
import { AnalysisFilterGroupModel } from 'pages/analysis/models/analysis-filter-group.model';
import { AnalysisFilterModel } from '@ic/component-lib/src/components/modules/analysis-filters/models';
import { AnalysisStateFiltersModel } from 'pages/analysis/models/analysis-state.model';
import {Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { DatasetModel } from 'components/rest/services/datasets-repository/models';
import { AnalysisAppliedFiltersService } from 'components/common/services/analysis-applied-filters/analysis-applied-filters.service';
import { TranslateService } from '@ngx-translate/core';
import { ElementRef } from '@angular/core';
import {TileService} from 'components/rest/services/tile/tile.service';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { Globals } from 'components/shared/globalData';
import { SettingsRepositoryService } from 'components/rest/services/settings-repository/settings-repository.service';
import {EntityModel} from 'components/rest/services/entities-repository/entity.model';
import { Subscription } from 'rxjs';
import { IcTogglesNgService } from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';
import {Router} from '@angular/router';
import {LocaleService} from 'components/common/services/locale/locale.service';

@Component({
  selector: 'ic-sidebar-filters',
  templateUrl: 'sidebar-filters.component.html',
  host: {
    '(document:click)': 'onClick($event)',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarFiltersComponent implements OnChanges {
  @Input() outsideClickEvent: boolean;
  @Input() datasets: DatasetModel[] = [];
  @Input() includeEsciDocuments: boolean = false;
  @Input() filters!: AnalysisStateFiltersModel;
  @Output() datasetChange = new EventEmitter<string>();
  @Output() filterApply = new EventEmitter<AnalysisAppliedFiltersModel>();
  @Output() esciToggle = new EventEmitter();
  @Output() inputFilters = new EventEmitter();
  @Output() showChooseTreeModal = new EventEmitter();
  @Input() dataUpdateInfo: string = '';
  @Input() context: string = '';
  @Input() entity: EntityModel | undefined;
  public periodFilter: AnalysisFilterModel | undefined;
  public currentDatasetId!: string;
  public activeFilterGroup!: AnalysisFilterGroupModel | null;
  public detailsOpen = false;
  public detailsClosing = false;
  currentLang: string;
  menuOpened: boolean = false;
  currentDataSetTitle!: string;
  isPreprintsDatasetSelected: boolean = false;
  env = this.environmentService.getEnvironment();
  IC_39296_AUTHORPOSITION: boolean = false;
  toggleSubscription: Subscription;

  constructor(
    private appliedFiltersService: AnalysisAppliedFiltersService,
    private translate: TranslateService,
    private eRef: ElementRef,
    private tileService: TileService,
    public globalData: Globals,
    private environmentService: EnvironmentService,
    private settingsRepositoryService: SettingsRepositoryService,
    private icTogglesNgService: IcTogglesNgService,
    private router: Router,
    private localeService: LocaleService,
  ) {

    this.currentLang = this.translate.store.currentLang;

    this.appliedFiltersService.closeModel.subscribe(() => {
      this.onDetailsClose();
    });

    if (!this.globalData?.toggleData) {
      this.setSplitIoToggles();
    } else {
      const IC_39296_AUTHORPOSITION = this.env.splitioTreatment.IC_39296_Author_Position_Indicators_Filters;
      this.IC_39296_AUTHORPOSITION = this.globalData?.toggleData[IC_39296_AUTHORPOSITION] === 'on';
    }
  }

  setSplitIoToggles() {
    this.toggleSubscription = this.icTogglesNgService.toggles().subscribe((toggles) => {
      this.globalData.toggleData = toggles;
      const IC_39296_AUTHORPOSITION = this.env.splitioTreatment.IC_39296_Author_Position_Indicators_Filters;
      this.IC_39296_AUTHORPOSITION = toggles[IC_39296_AUTHORPOSITION] === 'on';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasets && changes.datasets.currentValue && changes.datasets.currentValue.length > 0) {
      this.currentDatasetId = this.datasets.find((dataset) => dataset.selected)!.datasetId;
      this.currentDataSetTitle = this.datasets.find(dataset => dataset.selected)!.title;
      this.isPreprintsDatasetSelected = (this.currentDatasetId === '1') ? true : false;
    }
    if (changes.filters && changes.filters.currentValue) {
      this.periodFilter = this.filters.filters.find((filter) => filter.name === 'period');
    }
  }

  singleTileSelect() {
    return this.tileService.getSelectedTilesCount() === 1;
  }

  get currentDataset() {
    if (!this.datasets.length) {
      return null;
    }
    return this.datasets.filter(dataset => dataset.datasetId === this.currentDatasetId)[0];
  }

  openFilterGroup(group: AnalysisFilterGroupModel, sidebarDetails: HTMLElement) {
    this.hideMenu();
    this.activeFilterGroup = group;
    // TODO: load filter data, then open details panel
    this.detailsOpen = true;
    const y = sidebarDetails.getBoundingClientRect().top + window.pageYOffset - 100;
    window.scrollTo(0, y);
  }

  onDatasetChange() {
    this.settingsRepositoryService.listEntities.next(this.currentDatasetId);
    this.datasetChange.emit(this.currentDatasetId);
  }

  onDateRangeChange(range: number[]) {
    if (range[0] < 2008 && !this.IC_39296_AUTHORPOSITION) {
      this.filterApply.emit({ period: { is: range }, authorposition: { is: [] }});
    } else {
      this.filterApply.emit({ period: { is: range } });
    }
  }

  onDetailsClose() {
    const closeAnimationDuration = 350; // must match the CSS animation duration
    this.detailsClosing = true;
    window.setTimeout(() => {
      this.detailsOpen = false;
      this.detailsClosing = false;
      this.activeFilterGroup = null;
    }, closeAnimationDuration);
  }

  onEsciChange() {
    this.esciToggle.emit();
  }

  onFilterApply(event: AnalysisAppliedFiltersModel) {
    this.filterApply.emit(event);
  }

  isAnyFilterApplied(filterGroup: AnalysisFilterGroupModel): boolean {
    const appliedFilters = Object.keys(this.filters.values);
    return filterGroup.filters!.some(filter => this.appliedFiltersService.isFilterApplied(filter, appliedFilters));
  }

  goToAnalysis() {
    this.router.navigateByUrl(this.localeService.href('/analysis'));
  }

  hideMenu() {
    this.menuOpened = false;
  }

  showMenu() {
    this.menuOpened = true;
  }

  toggleMenu() {
    this.menuOpened ? this.hideMenu() : this.showMenu();
  }

  // tslint:disable-next-line:no-any
  onClick(event: { target: any; }) {
    if (!this.eRef.nativeElement.contains(event.target))
      this.hideMenu();
  }

  // tslint:disable-next-line:no-any
  emittedInputFilters(event: any) {
    this.inputFilters.emit(event);
  }
}
