import { CommonModule as NgCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

// modules imported from component library
import { AlertsBannerModule } from '@ic/component-lib/src/components/modules/alerts-banner/alerts-banner.module';
import { ChartModule } from '@ic/component-lib/src/components/modules/chart/chart.module';
import { IconModule } from '@ic/component-lib/src/components/modules/icon/icon.module';
import { MultiSelectModule } from '@ic/component-lib/src/components/modules/multi-select/multi-select.module';
import { OverlayModalModule } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.module';
import { PopoverModalModule } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.module';
import { SlideinModalModule } from '@ic/component-lib/src/components/modules/slidein-modal/slidein-modal.module';
import { TextInputModule } from '@ic/component-lib/src/components/modules/text-input/text-input.module';
import { TreeViewModule } from '@ic/component-lib/src/components/modules/tree-view/tree-view.module';

// components and services in this module
import { BrowserDetectionService } from './services/browser-detection/browser-detection.service';
import { ChartImageGeneratorService } from './services/chart-image-generator/chart-image-generator.service';
import { ChooseDepartmentComponent } from './components/choose-department/choose-department.component';
import { ChooseDepartmentService } from './services/choose-department/choose-department.service';
import { DiagramPdfGeneratorService } from './services/pdf-generator/diagram-pdf-generator.service';
import { DocumentRefService } from './document/document-ref';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EnvironmentService } from '../app/services/environment/environment.service';
import { EventsTrackerService } from './services/events-tracker/events-tracker.service';
import { ExportComponent } from './components/export/export.component';
import { FileSystemService } from './services/file-system/file-system.service';
import { FolderSelectComponent } from './components/folder-select/folder-select.component';
import { FriendlyUrlService } from './services/friendly-url/friendly-url.service';
import { getLocalStorage } from './services/local-storage/local-storage.factory';
import { GoogleAnalyticsService } from '../common/services/google-analytics/google-analytics.service';
import { LocaleService } from './services/locale/locale.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { NotificationBannerService } from './services/notification-banner/notification-banner.service';
import { PdfService } from './services/pdf-generator/pdf.service';
import { ReportPdfGeneratorService } from './services/pdf-generator/report-pdf-generator.service';
import { RichTileComponent } from './components/rich-tile/rich-tile.component';
import { SnowplowService } from './services/snowplow/snowplow.service';
import { TableListTileComponent } from '../../components/common/components/table-list-tile/table-list-tile.component';
import { WindowRefService } from './window/window-ref.service';
import { PngGeneratorService } from './services/png-generator/png-generator.service';
import { WosViewModalComponent } from 'components/common/components/wos-view-modal/wos-view-modal.component';
import { LoadingIndicatorModule } from '@ic/component-lib/src/components/modules/loading-indicator/loading-indicator.module';
import { AnalysisEntityService } from 'components/common/services/analysis-entity/analysis-entity.service';
import { AnalysisAppliedFiltersService } from 'components/common/services/analysis-applied-filters/analysis-applied-filters.service';
import {AnalysisFiltersService} from 'components/common/services/analysis-filters/analysis-filters.service';
import {DeleteContentComponent} from 'components/common/components/delete-content/delete-content.component';
import { AlertComponent } from './components/alert-component/alert-content.component';
import {IcTogglesNgService} from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';
import { ChooseTreeModalComponent } from './components/choose-tree-modal/choose-tree-modal.component';
import {DocumentListDownloadComponent} from 'components/common/components/document-list-download/document-list-download.component';
import { EditFilterComponent } from './components/popUpOnPlusMoreText/edit-filter-items.component';
import {SidebarBaselinesComponent} from 'components/common/components/analysis/sidebar-baselines/sidebar-baselines.component';
import {SidebarFilterComponent} from 'components/common/components/analysis/sidebar-filter/sidebar-filter.component';
import {SidebarFiltersComponent} from 'components/common/components/analysis/sidebar-filters/sidebar-filters.component';
import {SidebarIndicatorComponent} from 'components/common/components/analysis/sidebar-indicator/sidebar-indicator.component';
import {SidebarIndicatorsComponent} from 'components/common/components/analysis/sidebar-indicators/sidebar-indicators.component';
import {AnalysisSidebarComponent} from 'components/common/components/analysis/analysis-sidebar/analysis-sidebar.component';
import { AnalysisFiltersModule } from '@ic/component-lib/src/components/modules/analysis-filters/analysis-filters.module';
import { TextTruncationModule } from '@ic/component-lib/src/components/utility/text-truncation/text-truncation.module';
import { TooltipModule } from '@ic/component-lib/src/components/modules/tooltip/tooltip.module';
import { TabNavModule } from '@ic/component-lib/src/components/modules/tab-nav/tab-nav.module';
import {DateRangeFilterComponent} from 'components/common/components/analysis/date-range-filter/date-range-filter.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { TextHighlightModule } from '@ic/component-lib/src/components/modules/text-highlight/text-highlight.module';
import { RangeInputModule } from '@ic/component-lib/src/components/modules/range-input/range-input.module';
import {AnalysisService} from 'pages/analysis/services/analysis-service/analysis.service';
import {ChartOptionsService} from 'pages/analysis/services/chart-options/chart-options.service';
import {ConfirmComponent} from 'components/common/components/confirm-component/confirm.component';
import { FormattedRangeInputModule } from '@ic/component-lib/src/components/modules/formatted-range-input/formatted-range-input.module';
import { DlmSearchRowsComponent } from './components/dlm-search-rows/dlm-search-rows.component';
import { HighlightSearch } from '@ic/component-lib/src/components/modules/tree-view/highlight.pipe';
import {RouteReuseStrategy} from '@angular/router';
import {ReloadRouteReuseStrategy} from 'components/common/services/route-reuse/route-reuse-strategy.service';

// tslint:disable-next-line:no-any
(window as any).retain = [
  ExportComponent,
  RichTileComponent,
  TableListTileComponent,
  WosViewModalComponent
];

@NgModule({
  imports: [
    AlertsBannerModule,
    ChartModule,
    FormsModule,
    IconModule,
    MultiSelectModule,
    NgCommonModule,
    OverlayModalModule,
    PopoverModalModule,
    ReactiveFormsModule,
    SlideinModalModule,
    TextInputModule,
    TranslateModule,
    TreeViewModule,
    LoadingIndicatorModule,
    AnalysisFiltersModule,
    TextTruncationModule,
    TooltipModule,
    TabNavModule,
    DragDropModule,
    TextHighlightModule,
    RangeInputModule,
    FormattedRangeInputModule
  ],
  declarations: [
    DropdownComponent,
    ChooseDepartmentComponent,
    ExportComponent,
    FolderSelectComponent,
    NotificationBannerComponent,
    RichTileComponent,
    TableListTileComponent,
    WosViewModalComponent,
    DeleteContentComponent,
    AlertComponent,
    ChooseTreeModalComponent,
    DocumentListDownloadComponent,
    EditFilterComponent,
    AnalysisSidebarComponent,
    SidebarBaselinesComponent,
    SidebarFilterComponent,
    SidebarFiltersComponent,
    SidebarIndicatorComponent,
    SidebarIndicatorsComponent,
    DateRangeFilterComponent,
    ConfirmComponent,
    DlmSearchRowsComponent
  ],
  exports: [
    DropdownComponent,
    ChooseDepartmentComponent,
    ExportComponent,
    FolderSelectComponent,
    NotificationBannerComponent,
    RichTileComponent,
    TableListTileComponent,
    WosViewModalComponent,
    DeleteContentComponent,
    AlertComponent,
    ChooseTreeModalComponent,
    DocumentListDownloadComponent,
    EditFilterComponent,
    AnalysisSidebarComponent,
    SidebarBaselinesComponent,
    SidebarFilterComponent,
    SidebarFiltersComponent,
    SidebarIndicatorComponent,
    SidebarIndicatorsComponent,
    DateRangeFilterComponent,
    ConfirmComponent,
    HighlightSearch
  ],
  providers: [
    { provide: 'LocalStorage', useFactory: getLocalStorage },
    BrowserDetectionService,
    ChartImageGeneratorService,
    ChooseDepartmentService,
    DiagramPdfGeneratorService,
    DocumentRefService,
    EnvironmentService,
    EventsTrackerService,
    FileSystemService,
    FriendlyUrlService,
    GoogleAnalyticsService,
    LocaleService,
    LocalStorageService,
    NotificationBannerService,
    PdfService,
    ReportPdfGeneratorService,
    SnowplowService,
    WindowRefService,
    PngGeneratorService,
    AnalysisEntityService,
    AnalysisAppliedFiltersService,
    AnalysisFiltersService,
    IcTogglesNgService,
    AnalysisService,
    ChartOptionsService,
    HighlightSearch,
    { provide: RouteReuseStrategy, useClass: ReloadRouteReuseStrategy }
  ]
})

export class CommonModule {}
