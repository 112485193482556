import {Component} from '@angular/core';
import {UserService} from 'components/auth/services/user/user.service';
import {Router} from '@angular/router';

@Component({
  template : '<ng-container></ng-container>'
})

export class RedirectComponent {
  constructor(private userService: UserService, router: Router) {
    if (this.userService.getCurrentUser()) {
      router.navigate(['/landing']);
    } else {
      router.navigate(['/auth/signin']);
    }
  }
}