import { Component } from '@angular/core';
import {SecurityService} from 'components/security/security.service';
import {PendoService} from 'components/app/services/pendo/pendo.service';
import {MainContainerService} from 'components/app/services/main-container/main-container.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  modifierClasses: string = '';
  constructor(private securityService: SecurityService, private pendoService: PendoService, private mainContainerService: MainContainerService) {
  }

  ngOnInit() {
    this.securityService.checkAuthentication();
    this.mainContainerService.getModifierClassesObservable()
      .subscribe(value => {
        this.modifierClasses = value;
        this.pendoService.setup();
      });
  }
}