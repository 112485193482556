import { Injectable } from '@angular/core';
import { LocalStorageService } from './../../../common/services/local-storage/local-storage.service';

@Injectable()
export class DatasetsVersionService {
  datasetsVersion: string = '';

  constructor(private localStorageRef: LocalStorageService) {}

  getDatasetsVersion(): string {
    return this.datasetsVersion;
  }

  setDatasetsVersion(version: string) {
    this.datasetsVersion = version;
    if (
      this.localStorageRef.getItem('datasetsVersion') !== this.datasetsVersion
    ) {
      this.localStorageRef.clear();
    }
    this.localStorageRef.setItem('datasetsVersion', this.datasetsVersion);
  }
}
