import { Injectable } from '@angular/core';
import { extend, cloneDeep } from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ReportTileRequestModel } from 'components/common/interfaces/report.model';
import { ExploreTileResponseModel } from 'components/common/interfaces/exploretile.response.model';
import { map } from 'rxjs/operators';
import { Indicator } from 'components/common/interfaces/indicator.interface';
import {RequestParamsModel} from 'pages/analysis/models/analysis-state.model';
import {AnalysisAppliedFilterValuesModel, AnalysisFilterOptionParamsModel} from 'pages/analysis/models';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { Globals } from 'components/shared/globalData';
import {isNullOrUndefined} from 'util';
import {NavigationResponseInterface} from 'components/analytics/interfaces/navigation-response.interface';
const baseUrl: string = '/incites-app/';

@Injectable()
export class DataRepositoryService {
  private envConfig: {[key: string]: any}; // tslint:disable-line:no-any
  private serviceUrl: string = `${baseUrl}explore/`;

  constructor(private http: HttpClient,
    private globalData: Globals,
    private environmentService: EnvironmentService) {
    this.envConfig = this.environmentService.getEnvironment();
  }

  updateParams(request: RequestParamsModel, entity: string) {
    let requestCopy = cloneDeep(request);
    if (entity === 'department') {
      if (this.envConfig.analysis.filters.omitOnApply.department in requestCopy.filters) {
        delete requestCopy.filters[this.envConfig.analysis.filters.omitOnApply.department[0]];
      }
    }

    let isSubject = entity === 'subject';
    let isRequestWithoutemtCode = !requestCopy.indicators.includes('emtCode');
    let isEmergingTopicSchema = (requestCopy.filters?.schema as AnalysisAppliedFilterValuesModel)?.is === 'Emerging Topics';
    let isMicroLevel = (requestCopy.filters?.schemalevel as AnalysisAppliedFilterValuesModel)?.is === 'Micro';
    let isMacroLevel = (requestCopy.filters?.schemalevel as AnalysisAppliedFilterValuesModel)?.is === 'Macro';
    let isRequestWithSbjName = !isNullOrUndefined(requestCopy.filters?.sbjname);
    if (isSubject && isRequestWithoutemtCode && isEmergingTopicSchema && ( isMicroLevel || ( isMacroLevel && isRequestWithSbjName ))) {
      requestCopy.indicators.push('emtCode');
    }

    return requestCopy;
  }

  explore(
    datasetId: string,
    entity: string,
    request:  RequestParamsModel,
    queryDataCollection: string | undefined
  ): Observable<ExploreTileResponseModel> {

    let newRequest = this.updateParams(request, entity);

    return this.exploreByUrl(
      `${this.serviceUrl}${datasetId}/${entity}/data/table/page`,
      newRequest,
      queryDataCollection
    );
  }
  getEntities(
    datasetId: string,
    iconId: string) {
    if (datasetId === '') {
      datasetId = '0';
    }
    if (iconId === '') {
      iconId = 'researcher';
    }
    const url = `/incites-app/explore/${datasetId}/entities/${iconId}`;
    return this.http.get<NavigationResponseInterface[]>( url );
  }
  getCollections() {
    const url = `/incites-app/explore/filter/collections`;
    const bnaUrl = `/bna/user/collections`;
    const nginxUrl = `/route/bna/user/collections`;
    if (this.getSplitIOToggles(this.envConfig.splitioTreatment.IFE_IC_40291_Enable_nginx_route_changes)) {
      return this.http.get<[]>(nginxUrl);
    } else {
      if (this.getSplitIOToggles(this.envConfig.splitioTreatment.isMicroServiceForCollectionEnabled)) {
        return this.http.get<[]>(bnaUrl);
      }
      return this.http.get<[]>(url);
    }

  }

  getSplitIOToggles(toggleValue: string): boolean {
    return (this.globalData?.toggleData && (this.globalData?.toggleData[toggleValue] === 'on'));
  }

  exploreByUrl(
    url: string,
    request: {},
    queryDataCollection?: string
  ): Observable<ExploreTileResponseModel> {
    return this.http.post<ExploreTileResponseModel>(
      url,
      request,
      this.getGetParams(queryDataCollection)
    );
  }

  getDetailsfromPageApi(name: string, cekValue?: string, queryDataCollection?: string): Observable<AnalysisFilterOptionParamsModel> {
    return this.http.get<AnalysisFilterOptionParamsModel>(`${baseUrl}member/group?cek=${cekValue}&name=${encodeURIComponent(name)}&query=${queryDataCollection}`);
  }

  count(
    datasetId: string,
    entity: string,
    request: { filters: { [index: string]: Partial<any>; }; }, // tslint:disable-line:no-any
    queryDataCollection: string | undefined
  ): Observable<{ totalItems: number }> {

    let sampleRequest = cloneDeep(request);
    if (entity === 'department') {
      if (this.envConfig.analysis.filters.omitOnApply.department in sampleRequest.filters) {
        delete sampleRequest.filters[this.envConfig.analysis.filters.omitOnApply.department[0]];
      }
    }

    return this.http.post<{ totalItems: number }>(
      `${this.serviceUrl}${datasetId}/${entity}/data/table/total`,
      sampleRequest,
      this.getGetParams(queryDataCollection)
    );
  }

  getBenchmarks(
    datasetId: string,
    entity: string,
    request: ReportTileRequestModel,
    queryDataCollection: string | undefined
  ) {

    let sampleRequest = cloneDeep(request);
    if (entity === 'department') {
      if (this.envConfig.analysis.filters.omitOnApply.department in sampleRequest.filters) {
        delete sampleRequest.filters[this.envConfig.analysis.filters.omitOnApply.department[0]];
      }
    }
    return this.exploreByUrl(
      `${this.serviceUrl}${datasetId}/${entity}/data/table/benchmarks`,
      sampleRequest,
      queryDataCollection
    );
  }

  exclude(
    datasetId: number | string,
    entity: string,
    request: {},
    items: [{}] | string[],
    queryDataCollection: string | undefined
  ): Promise<{ [p: string]: object }> {
    let rest: { [index: string]: {} } = {};

    extend(rest, request);
    rest.transEntities = items;

    return this.http.post<{ personId: Object, wppersonId: Object }>(
      `${this.serviceUrl}${datasetId}/${entity}/exclude`,
      rest,
      this.getGetParams(queryDataCollection)
    )
      .toPromise();
  }

  getTableTileValuesAndIndicators(url: string) {
    return this.http.get<{ value: Array<Object>, columns: Array<Indicator> }>(url)
      .pipe(
        map((response) => ({ value: response.value, indicators: response.columns }))
      );
  }

  validatePinnedGroup(request: RequestParamsModel) {
    const requestParamsCopy = cloneDeep(request);
    if (Array.isArray(requestParamsCopy.groupPinned) && requestParamsCopy.groupPinned.length > 0) {
      const groupPinned = requestParamsCopy.groupPinned;
      delete requestParamsCopy.groupPinned;
      requestParamsCopy.pinned = groupPinned.map(groupId => {
        if (groupId.includes('_')) {
          groupId = groupId.split('_')[1].split('_')[0];
        }
        return groupId;
      });
    }

    return requestParamsCopy;
  }

  groups(
    datasetId: string,
    entity: string,
    request: RequestParamsModel,
    queryDataCollection: string | undefined,
    url ?: string
  ): Observable<ExploreTileResponseModel> {
    if (entity === 'department') {
      entity = 'organization';
      request.compositeEntityKey = 'organization::Group';
      request.sortBy = 'timesCited';
      // @ts-ignore
      return of({
        'items': [],
        'indicators': [
          {
            'name': 'orgName',
            'type': 'string',
            'title': 'Organization Name'
          },
          {
            'name': 'wosDocuments',
            'type': 'number',
            'unit': 'count',
            'title': 'Web of Science Documents'
          },
          {
            'name': 'timesCited',
            'type': 'number',
            'unit': 'count',
            'title': 'Times Cited'
          },
          {
            'name': 'percentCited',
            'type': 'number',
            'unit': 'percentage',
            'title': '% Documents Cited'
          },
          {
            'name': 'rank',
            'type': 'number',
            'unit': 'rank',
            'title': 'Rank'
          },
          {
            'name': 'norm',
            'type': 'number',
            'title': 'Category Normalized Citation Impact'
          },
          {
            'name': 'key',
            'type': 'string',
            'title': 'Unique Key'
          },
          {
            'name': 'seqNumber',
            'type': 'number',
            'title': 'Sequence Number'
          },
          {
            'name': 'hasProfile',
            'type': 'boolean',
            'title': 'Has Profile'
          },
          {
            'name': 'deptName',
            'type': 'string',
            'title': 'Department Name'
          },
          {
            'name': 'parentOrgName',
            'type': 'string',
            'title': 'Parent Organization'
          }
        ],
        'sortBy': 'timesCited',
        'sortOrder': 'desc',
        'skip': 0,
        'take': 25,
        'isSourceYear': null,
        'citedOrCiting': null,
        'extraType': null,
        'queryDataBase': null,
        'dataExport': false,
        'total': 0
      });
    } else {
      let updatedRequest = this.validatePinnedGroup(request);
      return this.exploreByUrl(
        url ? url : `${this.serviceUrl}${datasetId}/${entity}/data/table/groups`,
        updatedRequest,
        queryDataCollection
      );
    }
  }

  private getGetParams(queryDataCollection: string | undefined) {
    if (queryDataCollection) {
      const httpParams = new HttpParams({ fromObject: { queryDataCollection: queryDataCollection } });

      return { params: httpParams };
    }

    return undefined;
  }

}