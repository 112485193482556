import { createChart } from '@ic/charts/src/chart';
import { DiagramOptionsService } from 'components/diagram/services/diagram-options/diagram-options.service';
import { Injectable, Input } from '@angular/core';
import { mergeMap, tap } from 'rxjs/operators';
import { Observable, from, timer } from 'rxjs';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { utilities } from 'components/common/utilities/utilities';
import { WindowRefService } from 'components/common/window/window-ref.service';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { AnalysisStateModel } from 'pages/analysis/models/analysis-state.model';
import { environmentCommonConfig } from 'components/app/services/environment/environment-common.config';

@Injectable()
export class ChartImageGeneratorService {

  private window!: Window;
  private counter = 0;
  @Input() state!: AnalysisStateModel;

  constructor(
    private diagramOptions: DiagramOptionsService,
    private windowRef: WindowRefService,
    private environmentService: EnvironmentService
  ) {
    this.window = this.windowRef.nativeWindow();
  }

  tileToPngBase64(tile: ReportTileModel): Observable<string> {
    const { container, id } = this.createContainer();
    const chart = createChart(`#${id}`, this.getDiagramOptions(tile, id));
    const diagramOptions = this.getDiagramOptions(tile, id);
    const isSbjName = (diagramOptions && diagramOptions.series && diagramOptions.series.metadata && diagramOptions.series.metadata.name &&
      diagramOptions.series!.metadata.name.name === 'sbjName') ? true : false;
    let isAmchartsEnabled = true;

    try {
      const isAmchartsTreeMap = this.environmentService.getEnvironment().feature.treemapPOC &&
        ['tree map', 'treemap amCharts'].includes(JSON.parse(tile.vis).type);
      const isAmchartsBar = this.environmentService.getEnvironment().feature.barAmchartsPOC &&
        ['bar chart', 'bar amCharts'].includes(JSON.parse(tile.vis).type);
      const isAmchartsHeatmap = this.environmentService.getEnvironment().feature.heatmapAmchartsPOC &&
        ['heat map', 'heatmap amCharts'].includes(tile.tileType);
      const isAmchartsTreeMapSDG = isSbjName && JSON.parse(tile.vis).type === 'treemap amchartsSDG'
        && JSON.parse(tile.params).request.filters.schema.is === environmentCommonConfig.SDG;
      const isAmchartsGeoMap = this.environmentService.getEnvironment().feature.amchartsPOC &&
        ['map chart', 'map amCharts'].includes(JSON.parse(tile.vis).type);
      const isAmchartsMultiIndicatorTrendGraph = this.environmentService.getEnvironment().feature.multiIndicatorTrendGraphPOC &&
        ['multiIndicatorLine amCharts', 'multiIndicatorLine chart'].includes(JSON.parse(tile.vis).type);
      const isAmchartsTrendGraph = this.environmentService.getEnvironment().feature.trendGraphPOC &&
        ['line chart', 'line amCharts'].includes(JSON.parse(tile.vis).type);
      const isAmchartsFiveYearTrendGraph = this.environmentService.getEnvironment().feature.fiveYearTrendGraphPOC &&
        ['period chart', 'period amCharts'].includes(JSON.parse(tile.vis).type);
      const isAmChartsWordCloudGraph = this.environmentService.getEnvironment().feature.wordCloudPOC &&
        JSON.parse(tile.vis).type === 'wordCloud graph';
      const isAmChartsPieGraph = this.environmentService.getEnvironment().feature.pieAmchartsPOC &&
        JSON.parse(tile.vis).type === 'pie amCharts';

      isAmchartsEnabled = isAmchartsTreeMap || isAmchartsTreeMapSDG || isAmchartsGeoMap || isAmchartsMultiIndicatorTrendGraph || isAmchartsTrendGraph ||
        isAmchartsFiveYearTrendGraph || isAmChartsWordCloudGraph || isAmchartsBar || isAmchartsHeatmap || isAmChartsPieGraph;
    } catch (error) {
      console.log(error);
    }

    return timer(1500).pipe(
      mergeMap(() => isAmchartsEnabled ?
        from(chart.getPNGBase64()) as unknown as string :
        from(chart.toPngBase64(3))
      ),
      tap(() => this.removeContainer(container))
    );
  }

  private createContainer() {
    const container = this.window.document.createElement('div');
    const id = `echart${this.counter}`;

    container.id = id;
    container.className = 'chart-export';
    this.window.document.body.appendChild(container);
    this.counter++;

    return { container, id };
  }

  private removeContainer(container: HTMLDivElement) {
    this.window.document.body.removeChild(container);
  }

  private getDiagramOptions(tile: ReportTileModel, id: string) {
    let visOptions = utilities.fromJson(tile.vis);

    if (!visOptions.type) {
      visOptions.type = tile.tileType;
    }

    if (!visOptions.title) {
      visOptions.title = tile.tileType;
    }

    let benchmarks = tile.benchmarks;
    let options = this.diagramOptions.getOptions(
      visOptions,
      tile.value,
      tile.indicators,
      benchmarks && benchmarks.items,
      benchmarks && benchmarks.indicators,
      visOptions.dimensions,
      tile.noData
    );

    // TODO: temporary hack for incorrect updating series name
    // @ts-ignore
    if (options.type === 'map chart' || options.type === 'map amCharts') {
      options.series!.seriesName = 'hits';
    }

    if (!(options.center && options.center.title)) {
      if (tile.displayItems) { // For new system old reports displayItems is used as center title
        options.center = { title: tile.displayItems };
      } else if (tile.subtitle) { // For old system reports subtitle is used as center title
        options.center = { title: tile.subtitle };
      }
    }

    options.tileIndex = id;
    return options;
  }

}