import {AuthenticationService} from 'components/auth/services/authentication/authentication.service';
import {AuthorizationService} from 'components/auth/services/authorization/authorization.service';
import {NavigationStart, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {NavigationService} from 'components/navigation/services/navigation/navigation.service';
import {WindowRefService} from 'components/common/window/window-ref.service';

@Injectable()

export class SecurityService {
  constructor(private router: Router, private authService: AuthenticationService,
              private authorizationService: AuthorizationService,
              private windowRef: WindowRefService,
              private navigationService: NavigationService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.checkAuthorization(event.url);
      }
    });
  }

  private checkAuthorization(url: string) {
    const result = this.authorizationService.authorizeAccess(url);
    if (result !== true) {
      this.router.navigate([`/auth/${result}` as string]);
    }
  }

  checkAuthentication() {
    this.authService.isAuthenticated$.subscribe(user => {
      if (user === false) {
        this.redirectTo('signout');
      }
    });
  }

  redirectTo(link: string) {
    this.windowRef.nativeWindow().location.href = this.navigationService.localizeLink(`/incites-app/redirect/${link}`);
  }

}