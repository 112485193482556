import { Injectable } from '@angular/core';
import { WindowRefService } from '../../window/window-ref.service';

import { EventTrackerModel } from 'components/common/interfaces/event-tracker.model';

@Injectable()
export class GoogleAnalyticsService implements EventTrackerModel {
  private ga: (...rest: any[]) => {}; // tslint:disable-line:no-any

  constructor(private windowRef: WindowRefService) {
    // @ts-ignore
    this.ga = this.windowRef.nativeWindow().ga;
  }

  trackEvent(
    category: string,
    label?: string,
    action?: string|null,
    value?: string|null,
  ) {
    try {
      const fieldsObject = {
        eventCategory: category,
        eventLabel: label,
        eventAction: action,
        eventValue: value
      };

      this.ga('send', 'event', fieldsObject);
    } catch (error) {
      console.error(error);
    }
  }

  trackPageView(name: string|undefined, url: string|undefined) {
    if (name) {
      setTimeout(() => {
        this.ga('set', 'page', url);
        this.ga('send', 'pageview');
      });
    }
  }

}

