import { find, reject } from 'lodash';

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { LocaleService } from 'components/common/services/locale/locale.service';
import { WindowRefService } from 'components/common/window/window-ref.service';

import { LocaleModel } from './locale.model';
import {ActivationEnd, Params, Router} from '@angular/router';


@Component({
  selector: 'ic-locale-menu',
  templateUrl: './locale-menu.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LocaleMenuComponent implements OnInit {

  allLocales: LocaleModel[] = [
    { id: 'en-us', title: 'English', url: '/', debug: false, name: 'English' },
    { id: 'ru', title: 'Русский', url: '/ru', debug: true, name: 'Russian' },
    { id: 'zh', title: '简体中文', url: '/zh', debug: false, name: 'Chinese' },
    { id: 'debug', title: 'Debug', url: '/debug', debug: true, name: 'Debug' }
  ];

  locales: LocaleModel[] = [];
  currentLocale?: LocaleModel;
  IS_CDX_HEADER_ON = false;
  routeParams: Params;

  constructor(
    private environmentService: EnvironmentService,
    private localeService: LocaleService,
    private windowRef: WindowRefService,
    private router: Router
  ) {
    this.IS_CDX_HEADER_ON = this.environmentService.getEnvironment().feature.cdxHeaderFooter;
  }

  ngOnInit() {
    this.locales = this.getLocales();
    this.currentLocale = this.getCurrentLocale();

    this.router.events.subscribe(val => {
      if (val instanceof ActivationEnd) {
        if (Object.keys(val.snapshot.params).length > 0) {
          this.routeParams = val.snapshot.params;
        } else {
          this.routeParams = val.snapshot.firstChild?.params as Params;
        }
      }
    });
  }

  getCurrentLocale() {
    return find(this.allLocales, { id: this.localeService.id.toLowerCase() });
  }

   getLocales() {
    if (!this.environmentService.getEnvironment().showDebugLocales) {
      return reject(this.allLocales, function(locale) {
        return locale.debug === true;
      });
    } else {
      return this.allLocales;
    }
  }

  go(locale: LocaleModel) {
    const location = this.windowRef.nativeWindow().location;
    const currentLang = '/' + ((this.routeParams ? this.routeParams.lang : '') || '');
    const newLang = locale.id === 'en-us' ? '' : locale.url;

    const currentUrl = this.router.url;
    if (currentLang === '/') {
      location.replace(`${location.origin}/#${locale.url}${currentUrl}`);
    } else {
      location.replace(`${location.origin}/#${currentUrl.replace(currentLang ? currentLang : '/', newLang)}`);
    }
    location.reload();
  }
}