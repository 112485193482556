<cl-popover-modal
  class="cl-popover-modal--top-nav"
  [options]="{ isContext: true }"
>
  <cl-popover-modal-button
    [buttonClass]="'cl-btn cl-btn--text cl-btn--link cl-btn--with-icon cl-context-menu__btn'"
  >
    <span class="cl-btn__text">{{ currentLocale?.title }}</span>
    <cl-icon [iconId]="IS_CDX_HEADER_ON ? 'caret-down' : 'arrow-down'" [color]="'color-white'"></cl-icon>
  </cl-popover-modal-button>

  <cl-popover-modal-content>
    <ul class="cl-context-menu__list">
      <li class="cl-context-menu__list__item" *ngFor="let locale of locales">
        <button class="cl-btn cl-btn--text cl-btn--link cl-context-menu__list__item__btn"
                [attr.aria-label]="locale.name"
                (click)="go(locale)"
                >{{ locale.title }}</button>
      </li>
    </ul>
  </cl-popover-modal-content>

</cl-popover-modal>
