import {Injectable} from '@angular/core';
import { DatasetsVersionService } from './../datasets-version/datasets-version.service';
import { UserService } from './../user/user.service';
import { UserModel } from 'components/auth/interfaces/user.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

const baseUrl: string = '/incites-app/';

@Injectable()
export class AuthenticationService {
  private datasetsVersion = 'v2';
  private serviceUrl: string = `${baseUrl}authentication`;
  private userDetailsUrl: string = `${baseUrl}userdetails`;
  private isAuthenticatedSubject = new BehaviorSubject<boolean | null | UserModel>(null);
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor(
    private datasetsVersionService: DatasetsVersionService,
    private http: HttpClient,
    private userService: UserService
  ) {}

  authenticate(): Promise<any> { // tslint:disable-line: no-any
    if (this.isAuthenticatedSubject.value !== null) {
      // Return cached authentication status
      return Promise.resolve(this.isAuthenticatedSubject.value);
    }
    return this.http.get(this.userDetailsUrl)
      .pipe(
        map((response: any) => { // tslint:disable-line: no-any
          if (response && response.userId ) {
            const user: UserModel = <UserModel>response;
            if (user.email) {
              user.name = user.email;
            }
            this.userService.setCurrentUser(user);
            this.userService.initializeToggles();
            this.datasetsVersionService.setDatasetsVersion(this.datasetsVersion);
            this.isAuthenticatedSubject.next(user);

            return user;
          } else {
            this.userService.setCurrentUser(undefined);
            if (response && response.errorCode) {
              return Promise.reject(response.errorCode);
            }
          }
          return Promise.reject(401);
        })
      )
      .toPromise();
  }

  signOut(): Promise<{status: string}> {
    return this.http.get<{status: string}>(`${this.serviceUrl}/delete`)
      .toPromise()
      .then((data) => {
        if (data.status === 'done') {
          this.isAuthenticatedSubject.next(false);
          this.userService.setCurrentUser(undefined);
        }
        return data;
      });
  }
}
