import { Injectable } from '@angular/core';
import { intersection, isUndefined, isArray, isString } from 'lodash';
import { UserService } from './../user/user.service';

@Injectable()
export class AuthorizationService {
  constructor(private userService: UserService) {}

  isUserInRole(requiredRole: string|string[]): boolean {
    const user = this.userService.getCurrentUser();
    let roles = ['anonymous'];

    if (user && user.roles) {
      roles = user.roles;
    }
    if (isUndefined(requiredRole)) {
      return true;
    }
    if (isString(requiredRole)) {
      return roles.indexOf(requiredRole) >= 0;
    }
    if (!isArray(requiredRole)) {
      throw new Error('requiredRole should be either a string or an array');
    }

    return intersection(roles, requiredRole).length > 0;
  }

  authorizeAccess(state: any): boolean|string { // tslint:disable-line
    let roles = state.data && state.data.availableTo;

    if (this.isUserInRole(roles)) {
      return true;
    } else {
      return this.userService.getCurrentUser() ? 'forbidden' : 'signin';
    }
  }

}