import { Injectable } from '@angular/core';
import { UserModel } from 'components/auth/interfaces/user.model';

import { GoogleAnalyticsService } from 'components/common/services/google-analytics/google-analytics.service';
import { SnowplowService } from '../snowplow/snowplow.service';
import { WindowRefService } from 'components/common/window/window-ref.service';
import {AuthenticationService} from 'components/auth/services/authentication/authentication.service';
import {ActivationEnd, Data, Params, Router} from '@angular/router';

@Injectable()
export class EventsTrackerService {
  routeData: { [key: string]: string };
  routeParams: Params;
  constructor(
    private ga: GoogleAnalyticsService,
    private snowplow: SnowplowService,
    private windowRef: WindowRefService,
    private authService: AuthenticationService,
    private router: Router
  ) {

    this.authService.isAuthenticated$.subscribe(user => {
      if ((user as UserModel)?.userId) {
        this.registerUser(user as UserModel);
      }

      if (user === false) {
        this.registerUser(undefined);
      }
    });

    this.router.events.subscribe(val => {
      if (val instanceof ActivationEnd) {
        if (Object.keys(val.snapshot.data).length > 0) {
          this.routeData = val.snapshot.data;
        } else {
          this.routeData = val.snapshot.firstChild?.data as Data;
        }
        if (Object.keys(val.snapshot.params).length > 0) {
          this.routeParams = val.snapshot.params;
        } else {
          this.routeParams = val.snapshot.firstChild?.params as Params;
        }
      }
    });
  }

  /**
   * Track event
   * @constructor
   * @param {string} category - Category.
   * @param {string} label - Label.
   * @param {string} action - Action.
   * @param {string} value - Value.
   */
  trackEvent(
    category: string,
    label: string,
    action: string|null = null,
    value: string|null = null
  ): void {
    this.ga.trackEvent(category, <string>action, label, <string>value);
    this.snowplow.trackEvent(category, <string>action, label, <string>value);
  }

  trackPageView(): void {
    const pageName = this.processPageName(<string>this.routeData.name, this.routeParams);
    this.ga.trackPageView(pageName, this.windowRef.nativeWindow().location.hash.replace('#', '') +
      this.windowRef.nativeWindow().location.search);
    this.snowplow.trackPageView(this.routeData.name);
  }

  private registerUser(user: UserModel|undefined): void {
    if (user && user.userId) {
      this.snowplow.registerUser(user);
    }
  }

  private processPageName(pageName: string, params: Params): string | undefined {
    switch (pageName.replace('app.', '')) {
      case 'explore':
        return pageName + '.' + params.entityId;
      case 'report':
        return pageName + '.' + params.reportId;
      case 'redirect':
        return undefined;
      default:
        return pageName;
    }
  }
}

