import { Injectable } from '@angular/core';
import { InstitutionReportModel, OrgInfo } from 'components/common/interfaces/report.model';
import { omitBy, isNil, isUndefined } from 'lodash';

@Injectable()
export class FriendlyUrlService {

  constructor() {}

  sanitize(value?: string|number) {
    const regexp = /[^a-z0-9]+/ig;

    if (typeof value === 'string') {
      return value.replace(regexp, '-').toLowerCase();
    } else if (typeof value === 'number') {
      return value.toString();
    } else {
      return '';
    }
  }

  sanitizeInstitutionReportUrl(report: InstitutionReportModel): InstitutionReportModel {
    const sanitizedFacebookUrl = this.urlSanitizer(
      new RegExp(/((?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?(?:[\w\-.]*)?/i),
      [
        [/^\d\S+$/, (url: string) => `https://facebook.com/profile.php?id=${url}`],
        [/^[\w-.]$/, (url: string) => `https://facebook.com/profile.php?id=${url}`]
      ]);
    const sanitizedLinkedinUrl = this.urlSanitizer(new RegExp(/((?:http|https):\/\/)?(?:[\w]+.)?linkedin.com\//i), []);
    const sanitizedTwitterUrl = this.urlSanitizer(new RegExp(/((?:http|https):\/\/)?(?:[\w]+.)?twitter.com\//i), [
      [/^@[A-Za-z0-9_]{1,15}$/, (url: string) => `https://twitter.com/${url.substring(1)}`]
    ]);

    if (report.info) {
      const sanitizedInfo = this.mapInfo(report.info, (key: string, value: string) => {
        if (!value || value === 'n/a') {
          return undefined;
        }

        switch (key) {
          case 'facebookurl':
            return sanitizedFacebookUrl(value);
          case 'twitterurl':
            return sanitizedTwitterUrl(value);
          case 'linkedinurl':
            return sanitizedLinkedinUrl(value);
          default:
              return value;
        }
      });

      report.info = omitBy(sanitizedInfo, isNil) as OrgInfo;
    }

    return report;
  }

  // tslint:disable-next-line: no-any
  private urlSanitizer(protocolPattern: RegExp, patternMappings: Array<Array<any>>) {
    return (url: string) => {
      const cleanUrl = url.replace(/^\s+/, '');
      const matches = protocolPattern.exec(cleanUrl);

      if (matches !== null) {
        if (matches.length < 2 || isUndefined(matches[1])) {
          return 'https://' + cleanUrl;
        }
        return cleanUrl;
      }
      for (let key in patternMappings) {
        if (patternMappings.hasOwnProperty(key)) {
          let patternMapping = patternMappings[key];
          if (patternMapping[0].test(cleanUrl)) {
            return patternMapping[1](cleanUrl);
          }
        }
      }
    };
  }

  private mapInfo(obj: {[index: string]: string}, mapFn: Function) {
    return Object.keys(obj).reduce((result: {[index: string]: string}, key: string) => {

      result[key] = mapFn(key, obj[key]);

      return result;
    }, {});
  }

}
