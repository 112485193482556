import { Injectable } from '@angular/core';
import { cloneDeep, isObject, isUndefined } from 'lodash';
import { DatasetsVersionService } from './../../../auth/services/datasets-version/datasets-version.service';
import { LocalStorageService } from './../../../common/services/local-storage/local-storage.service';
import { UserService } from './../../../auth/services/user/user.service';

import { utilities } from 'components/common/utilities/utilities';


@Injectable()
export class UserStorageService {
  version: string;
  suffix: string;
  // tslint:disable-next-line:no-any
  previousDatasetState: any = {};
  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private datasetsVersionService: DatasetsVersionService
  ) {
    this.version = this.datasetsVersionService.getDatasetsVersion();
    this.suffix = `/${this.version}/explore/`;
  }

  generateExploreStateKey(entity: string, datasetId?: string) {
    return this.generateItemKey(this.suffix, entity, datasetId);
  }

  generateLastUsedDatasetKey() {
    return this.generateItemKey(this.suffix, 'lastUsedDataset');
  }

  generateLastUsedEntityKey() {
    return this.generateItemKey(this.suffix, 'lastUsedEntity');
  }

  generateReportColumnCountKey() {
    return this.generateItemKey('/', 'reportColumnCount');
  }

  generateWhatsNewVisitedKey(appVersion: string) {
    return this.generateItemKey('/' + appVersion + '/', 'whatsNewVisited');
  }

  generateTourVisitedKey() {
    return this.generateItemKey('/', 'tourVisited');
  }

  generateItemKey(suffix: string, item: string, datasetId?: string) {
    let user = this.userService.getCurrentUser();
    datasetId = datasetId && datasetId !== '0' ? '&' + datasetId : '';
    return user!.userId + suffix + item + datasetId;
  }

  saveExploreState(entity: string, state: any) { // tslint:disable-line
    const newState = cloneDeep(state);
    if (newState && newState.request && newState.request.indicators) {
      const indicators = newState.request.indicators; // split indicators to separate key
      delete newState.request.indicators;
      const indicatorsKey = this.generateExploreStateKey('indicators&' + `${entity}&` + newState.datasetId);
      const indicatorsData = utilities.toJson(indicators);
      this.localStorageService.setItem(indicatorsKey, indicatorsData || '');
    }

    const key = this.generateExploreStateKey(entity, newState.datasetId);
    const data = utilities.toJson(newState);
    this.localStorageService.setItem(key, data || '');
  }

  // tslint:disable-next-line:no-any
  sanitizeExploreStateRequestFilters(state: any) {
    if (state.request.filters) {
      for (let filter in state.request.filters) {
        if (state.request.filters.hasOwnProperty(filter)) {
          if (
            isObject(state.request.filters[filter]) &&
            !isUndefined(state.request.filters[filter].is)
          ) {
            if (
              state.request.filters[filter].is === null || (
                Array.isArray(state.request.filters[filter].is) &&
                state.request.filters[filter].is.length === 1 &&
                state.request.filters[filter].is[0] === null
              )
            ) {
              delete state.request.filters[filter].is;
            }
            if (Object.keys(state.request.filters[filter]).length === 0) {
              delete state.request.filters[filter];
            }
          }
        }
      }
    }
    return state;
  }

  loadExploreState(entity: string, datasetId: string) {
    let indicatorsKey = this.generateExploreStateKey('indicators&' + `${entity}&` + datasetId),
      indicatorsData = this.localStorageService.getItem(indicatorsKey),
      key = this.generateExploreStateKey(entity, datasetId),
      item = this.localStorageService.getItem(key),
      state = utilities.fromJson(item);

    if (state && state.request) {
      if (indicatorsData) {
        state.request.indicators = utilities.fromJson(indicatorsData);
      }
      state = this.sanitizeExploreStateRequestFilters(state);
    }

    if (state && state.visualization && state.visualization.type === 'many2many') {
      // temporary solution, because only many2many chart need this
      let chartStateId = this.localStorageService.getItem('chartStateId');
      if (chartStateId) {
        state.visualization.chartStateId = chartStateId;
      }
    }
    return state;
  }

  getLastUsedDatasetId() {
    let key = this.generateLastUsedDatasetKey(),
      item = this.localStorageService.getItem(key);
    return item;
  }

  setLastUsedDatasetId(datasetId: string) {
    let key = this.generateLastUsedDatasetKey();
    this.localStorageService.setItem(key, datasetId);
  }

  getLastUsedEntity() {
    let key = this.generateLastUsedEntityKey(),
      item = this.localStorageService.getItem(key);
    return item;
  }

  setLastUsedEntity(entity: string) {
    let key = this.generateLastUsedEntityKey();
    this.localStorageService.setItem(key, entity);
  }

  getReportColumnCount() {
    let key = this.generateReportColumnCountKey(),
      item = this.localStorageService.getItem(key);

    return item && Number(item);
  }

  setReportColumnCount(value: string|number) {
    let key = this.generateReportColumnCountKey();
    this.localStorageService.setItem(key, value.toString());
  }

  getTourVisited() {
    let key = this.generateTourVisitedKey(),
      item = this.localStorageService.getItem(key);
    return item && Boolean(item);
  }

  setTourVisited(visited: string|boolean) {
    let key = this.generateTourVisitedKey();
    this.localStorageService.setItem(key, visited.toString());
  }

  getWhatsNewVisited(appVersion: string) {
    let key = this.generateWhatsNewVisitedKey(appVersion),
      item = this.localStorageService.getItem(key);
    return item && Boolean(item);
  }

  setWhatsNewVisited(appVersion: string, visited: string|boolean) {
    let key = this.generateWhatsNewVisitedKey(appVersion);
    this.localStorageService.setItem(key, visited.toString());
  }

  // tslint:disable-next-line:no-any
  setPreviousDatasetState(state: any) {
    this.previousDatasetState = state;
  }

  getPreviousDatasetState(datasetId: string, entity: string) {
    if (Object.keys(this.previousDatasetState).length > 0 && this.previousDatasetState.datasetId !== datasetId &&
      this.previousDatasetState.entity === entity) {
      return this.previousDatasetState;
    }
    return false;
  }

  generateGroupStateKey(compositeEntityKey: string) {
    return this.generateItemKey(`/${compositeEntityKey}/`, 'isGroupHidden');
  }
  getGroupHiddenState(compositeEntityKey: string) {
    let key = this.generateGroupStateKey(compositeEntityKey);
    let item = this.localStorageService.getItem(key);
    return item && item === 'TRUE';
  }

  setGroupHiddenState(isGroupHidden: boolean, compositeEntityKey: string) {
    let key = this.generateGroupStateKey(compositeEntityKey);
    this.localStorageService.setItem(key, isGroupHidden ? 'TRUE' : 'FALSE');
  }
}