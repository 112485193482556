import { Injectable } from '@angular/core';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { UserService } from '../../../auth/services/user/user.service';
import { OtiService } from '@sp/oti-snippet';
import { UserModel } from 'components/auth/interfaces/user.model';

@Injectable()
export class PendoService {
  private apiKey: string;
  private envName: string;
  private isInitialized: boolean = false;
  private oneTrustKey: string;

  constructor(
    private environmentService: EnvironmentService,
    private userService: UserService
  ) {
    const env = this.environmentService.getEnvironment();

    this.apiKey = env.pendo.apiKey;
    this.oneTrustKey = env.oneTrustKey;
    this.envName = env.name;
    this.setup();
  }

  initializePendo(details: { env: string; sp: string; user: UserModel; }) {
    let env = details.env;
    let sp = details.sp;
    let user = details.user;
    const visitorId = user && user.userId ?
      user.userId + '_' + env :
      // tslint:disable-next-line:no-any
      (typeof (window as any).pendo !== 'undefined' && typeof (window as any).pendo.get_visitor_id === 'function') ?
        // tslint:disable-next-line:no-any
        (window as any).pendo.get_visitor_id() :
        '_' + env;

    const otiService = new OtiService({
      ot_key: this.oneTrustKey,
      ot_localStorage: true,
      prod_domain: 'incites.clarivate.com',
      pendo_key: this.apiKey,
      pendo_visitor_url: '/incites-app/user/visitor',
      pendo_visitor_payload: [{
        visitorId,
        values: {
          firstname: user.firstName,
          lastname: user.lastName
        }
      }],
      pendo_dnp_url: '/incites-app/user/dnp/' + visitorId,
      pendo_metadata_url: '/incites-app/user/pendo/metadata/' + visitorId,
      pendo_details: {
        visitor: {
          id: user.userId + '_' + env,
          email: user.name,
          full_name: user.firstName + ' ' + user.lastName,
          session: sp,
          sp: sp
        }, account: {
          id: user.customerId,
          name: user.customerName,
          environment: env,
          is_paying: (user.trialFlag === 'Y') ? false : true
        }
      }
    });

    otiService.initialize();
  }

  setup(): void {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;
    this.userService.getCurrentUserPromise().then((user) => {
      // tslint:disable-next-line:whitespace
      let match = document.cookie.match(new RegExp('_sp_id\\.[a-f0-9]+=([^;]+);?'));
      let sp = match ? match[1].split('.')[0] : '';

      this.initializePendo({
        env: this.envName === 'int2' || this.envName === 'qa2' ? 'test' : 'prod',
        sp: sp,
        user: user,
      });
    });
  }
}