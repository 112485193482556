import {Component} from '@angular/core';
import {SecurityService} from 'components/security/security.service';

@Component({
  template: '<div>Redirecting...</div>'
})

export class SignOutComponent {
  constructor(private securityService: SecurityService) {
    this.securityService.redirectTo('signout');
  }
}