/* tslint:disable:max-line-length */
import { CommonModule } from 'components/common/common.module';
import { CommonModule as NgCommonModule } from '@angular/common';
import { CreateFolderComponent } from './components/create-folder/create-folder.component';
import { CreateReportComponent } from './components/create-report/create-report.component';
import { DecimalPipe } from '@angular/common';
import { DownloadReportComponent } from './components/download-report/download-report.component';
import { FormatterService } from './filters/formatter/formatter.service';
import { IcFormatterPipe } from './pipes/formatter/formatter.pipe';
import { NgModule } from '@angular/core';
import { NumberFormatterService } from './filters/number-formatter/number-formatter.service';
import { OverbarComponent } from './components/overbar/overbar.component';
import { RedirectLinkPipe } from './pipes/redirect-link/redirect-link.pipe';
import { ReportShareComponent } from './components/report-share/report-share.component';
import { SnackBarComponent } from './components/snackbar/snackbar.component';
import { TileComponent } from './components/tile/tile.component';
import { TileSaveComponent } from './components/tile-save/tile-save.component';
import { TranslateModule } from '@ngx-translate/core';
import { TileRenameComponent } from './components/tile-rename/tile-rename.component';

// Component library
import { BreadcrumbsModule } from '@ic/component-lib/src/components/modules/breadcrumbs/breadcrumbs.module';
import { DateRangeModule } from '@ic/component-lib/src/components/modules/date-range/date-range.module';
import { FoldersModule } from '@ic/component-lib/src/components/modules/folders/folders.module';
import { IconModule } from '@ic/component-lib/src/components/modules/icon/icon.module';
import { LoadingIndicatorModule } from '@ic/component-lib/src/components/modules/loading-indicator/loading-indicator.module';
import { MultiSelectModule } from '@ic/component-lib/src/components/modules/multi-select/multi-select.module';
import { NavigationModule } from '@ic/component-lib/src/components/modules/navigation/navigation.module';
import { OverlayModalModule } from '@ic/component-lib/src/components/modules/overlay-modal/overlay-modal.module';
import { PopoverModalModule } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.module';
import { SlideinModalModule } from '@ic/component-lib/src/components/modules/slidein-modal/slidein-modal.module';
import { TextInputLimiterModule } from '@ic/component-lib/src/components/modules/text-input-limiter/text-input-limiter.module';
import { TooltipModule } from '@ic/component-lib/src/components/modules/tooltip/tooltip.module';
import { TreeViewModule } from '@ic/component-lib/src/components/modules/tree-view/tree-view.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ReportSaveComponent } from './components/report-save/report-save.component';
import { TileRemoveComponent } from './components/tile-remove/tile-remove.component';
import { ReportCreateComponent } from './components/report-create/report-create.component';
import { FoldersListingComponent } from './components/folders-listing/folders-listing.component';
import { MenuDropdownComponent } from './components/menu-dropdown/menu-dropdown.component';
import { IcTitleSortPipe } from './pipes/title-sorting/title-sorting.pipe';
import { AnalysisTableColumnFactory } from 'components/common/factories/analysis-table-column/analysis-table-column.factory';
import { AnalysisTableRowFactory } from 'components/common/factories/analysis-table-row/analysis-table-row.factory';
import { SortableTableModule } from '@ic/component-lib/src/components/modules/sortable-table/sortable-table.module';
import { NumberInputModule } from '@ic/component-lib/src/components/modules/number-input/number-input.module';
import {AppliedFiltersModule} from '@ic/component-lib/src/components/modules/applied-filters/applied-filters.module';

// tslint:disable-next-line:no-any
(window as any).retain = [
  DownloadReportComponent,
  OverbarComponent,
  ReportShareComponent,
  SnackBarComponent,
];

@NgModule({
    imports: [
        BreadcrumbsModule,
        NgCommonModule,
        CommonModule,
        FormsModule,
        DateRangeModule,
        FoldersModule,
        IconModule,
        LoadingIndicatorModule,
        MultiSelectModule,
        NavigationModule,
        OverlayModalModule,
        PopoverModalModule,
        ReactiveFormsModule,
        SlideinModalModule,
        TextInputLimiterModule,
        TooltipModule,
        TranslateModule,
        TreeViewModule,
        SortableTableModule,
        NumberInputModule,
        AppliedFiltersModule
    ],
  declarations: [
    CreateFolderComponent,
    CreateReportComponent,
    DownloadReportComponent,
    IcFormatterPipe,
    IcTitleSortPipe,
    OverbarComponent,
    RedirectLinkPipe,
    ReportShareComponent,
    SnackBarComponent,
    TileComponent,
    TileSaveComponent,
    ReportSaveComponent,
    TileRemoveComponent,
    TileRenameComponent,
    FoldersListingComponent,
    ReportCreateComponent,
    MenuDropdownComponent
  ],
  exports: [
    CreateFolderComponent,
    CreateReportComponent,
    DownloadReportComponent,
    IcFormatterPipe,
    IcTitleSortPipe,
    OverbarComponent,
    RedirectLinkPipe,
    ReportShareComponent,
    SnackBarComponent,
    TileComponent,
    TileSaveComponent,
    ReportSaveComponent,
    TileRemoveComponent,
    TileRenameComponent,
    FoldersListingComponent,
    ReportCreateComponent,
    MenuDropdownComponent
  ],
  providers: [
    DecimalPipe,
    FormatterService,
    IcFormatterPipe,
    NumberFormatterService,
    RedirectLinkPipe,
    AnalysisTableColumnFactory,
    AnalysisTableRowFactory
  ]
})
export class UiModule {}