import { Component, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from '../../../snackbar/services/snackbar/snackbar.service';
import { SnackBarMessageModel } from '../../../snackbar/services/snackbar/snackbar.model';

@Component({
  selector: 'ic-snackbar',
  templateUrl: './snackbar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SnackBarComponent {
  public message$ = this.snackBarService.messageChange$;

  constructor(
    public snackBarService: SnackBarService,
    readonly snackBar: MatSnackBar,
  ) {
    this.message$.subscribe(this.open.bind(this));
  }

  open(message: SnackBarMessageModel) {
    this.snackBar.open(message.message, message.action, message.config);
  }
}