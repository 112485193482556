import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { isNumber } from 'lodash';

@Injectable()
export class NumberFormatterService {
  constructor(
    private decimalPipe: DecimalPipe,
    private translate: TranslateService
  ) {}

  notAvailable = this.translate.instant('n/a');

  private formatNumber(input: string|number, metadata: any) { // tslint:disable-line
    switch (metadata.unit) {
      case 'percentage':
        return metadata.decimalPlaces === 2 ? 
                  this.decimalPipe.transform(input, '1.2-2') + '%' : 
                  this.decimalPipe.transform(input, '1.0-2') + '%';
      case 'year':
        return String(input);
      case 'rank':
      case 'count':
        if (+input % 1 || +input < 0) {
          return '';
        }
        break;
      case '2decimal':
        return Number(input).toFixed(2);
      case 'score':
        if (metadata.title === 'Eigenfactor') {
          return Number(input).toFixed(5);
        }
        break;
      case 'measure':
      default:
        if (typeof metadata.decimalPlaces !== 'undefined') {
          const decimalDigits = input.toString().split('.')[1]?.length || 0;
          if (metadata.title === 'Journal Impact Factor' && decimalDigits <= 1) {
            return Number(input).toFixed(1);
          }
          return Number(input).toFixed(metadata.decimalPlaces);
        }
        break;
    }

    return (input as number) < 1e-3 ? '0' : this.decimalPipe.transform(input, '1.0-2');
  }

  format(input: string|number , metadata: any) { // tslint:disable-line
    if (input == null) {
      return this.notAvailable;
    }

    if (metadata && metadata.type === 'number') {
      input = parseFloat(input.toString());
      if (isNumber(input) || /^\d*\.\d+(E\d+)?$/.test(input)) {
        return this.formatNumber(input, metadata);
      }
    }
    return input;
  }
}

