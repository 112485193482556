import { Injectable } from '@angular/core';
import { trackPageView } from '@snowplow/browser-tracker';
import { WindowRefService } from '../../../common/window/window-ref.service';
import { UserModel } from 'components/auth/interfaces/user.model';
import { EventTrackerModel } from 'components/common/interfaces/event-tracker.model';

@Injectable()
export class SnowplowService implements EventTrackerModel {
  private snowplow: (...attrs: any[]) => {}; // tslint:disable-line:no-any

  private static CLARIVATE_IGLU_SCHEMA = 'iglu:ne.clarivate.com/usage/jsonschema/2-0-0';
  private static userProps: object = {};
  // tslint:disable-next-line:no-any
  private static CONTEXT: any;

  constructor(
    private windowRef: WindowRefService
  ) {
    // @ts-ignore
    this.snowplow = this.windowRef.nativeWindow().snowplow;
  }

  init(user: UserModel) {
    SnowplowService.userProps = this.mapSnowplowFields(user);
    SnowplowService.CONTEXT = [{
      schema: SnowplowService.CLARIVATE_IGLU_SCHEMA,
      data: SnowplowService.userProps,
    }];
  }

  mapSnowplowFields(user: UserModel) {
    const result: {
      uid?: number,
      userid?: string,
      customerid?: number,
      custgrpid?: string,
      institution?: string,
      subsid?: string,
      shibid?: string,
      ipset?: string,
      user1?: string,
      user2?: string,
      account1?: string,
      account2?: string,
    } = {
      uid: user.userId,
      userid: user.name,
      customerid: user.customerId,
      custgrpid: String(user.customerId),
      institution: user.customerName,
      subsid: user.subscriptionGroupId,
      shibid: user.scopeValue,
      ipset: user.ipSetId,
      user1: user.accessMethod,
      user2: user.truid,
      account1: user.ipSetIdName,
      account2: user.shibOrg
    };

    Object.keys(result).forEach(
      // tslint:disable-next-line:no-any
      (key) => (result as any)[key] === undefined && delete (result as any)[key]
    );
    return result;
  }

  trackEvent(
    category: string,
    action?: string,
    label?: string|null,
    value?: string|null,
    ...rest: any[] // tslint:disable-line:no-any
  ) {
    // rest[0] impressionSet
    // rest[1] impressionId
    const impression = rest[0] && rest[1] ? rest[0] + ':' + rest[1] : '';

    this.snowplow(
      'trackStructEvent',
      category,
      action,
      label,
      impression,
      value,
      SnowplowService.CONTEXT,
    );
  }

  trackPageView (name: string|undefined) {
    if (name) {
      setTimeout(() => {
        trackPageView({
          title: name,
          context: SnowplowService.CONTEXT
        });
      });
    }
  }

  registerUser(user: UserModel|undefined) {
    if (user && user.userId) {
      this.snowplow('setUserId', user.userId);
    }
    if (user) {
      this.init(user);
    }
  }

}

