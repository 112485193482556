import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from 'components/auth/services/authentication/authentication.service';
import {SecurityService} from 'components/security/security.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthenticationService,
    private securityService: SecurityService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.authenticate().then(
      user => {
        if (!user?.userId) {
          this.securityService.redirectTo('signin');
          return false;
        }
        return true;
      },
      (errorCode: number) => {
        switch (errorCode) {
          case 403:
            this.securityService.redirectTo('forbidden');
            break;
          case 401:
          default:
            this.securityService.redirectTo('signin');
        }
        return false;
      }
    );
  }
}