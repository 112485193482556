import './polyfills';
import './styles/main.less';
import './styles/cdx/cdx.scss';
import './vendor/google-analytics.js';
import './vendor/snowplow.js';

import { platformBrowser } from '@angular/platform-browser';

import { AppModule } from './components/app/app.module';

import { enableProdMode } from '@angular/core';

platformBrowser().bootstrapModule(AppModule)
.catch(err => console.error(err));
enableProdMode();