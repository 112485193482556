import { Injectable } from '@angular/core';
import { isArray, isObject, isUndefined } from 'lodash';
import { WindowRefService } from 'components/common/window/window-ref.service';
import { environmentCommonConfig } from './environment-common.config';
import { environmentInt2Config } from './environment-int2.config';
import { environmentQa2Config } from './environment-qa2.config';
import { environmentQa3Config } from './environment-qa3.config';
import { environmentProdConfig } from './environment-prod.config';
@Injectable()
export class EnvironmentService {
  // tslint:disable-next-line:no-any
  env: { [key: string]: any } = {};
  environment: { [key: string]: {} } = {};

  private window: Window;

  constructor(
    windowRef: WindowRefService
  ) {
    this.window = windowRef.nativeWindow();
    this.environment = {
      ...{ int2: environmentInt2Config },
      ...{ qa2: environmentQa2Config },
      ...{ qa3: environmentQa3Config },
      ...{ prod: environmentProdConfig }
    };
    this.setEnvironment(this.environment);
  }

  getEnvironment() {
    return this.env;
  }

  // tslint:disable-next-line:no-any
  setEnvironment(config: { [key: string]: { [key: string]: any } }) {
    for (let group in config) {
      if (isObject(config[group]) && isUndefined(config[group].hostname) && isUndefined(config[group].hostnames)) {
        this.setEnvironment(config[group]);
        return;
      } else if (!isUndefined(config[group].hostname) && this.window.location.hostname === config[group].hostname) {
        this.env = {
          ...this.env,
          ...environmentCommonConfig,
          ...config[group]
        };
        if (isUndefined(this.env.name)) {
          this.env.name = group;
        }
        return;
      } else if (isArray(config[group].hostnames)) {
        let foundHost = false;

        for (let i = 0; i < config[group].hostnames.length; i += 1) {
          if (this.window.location.hostname === config[group].hostnames[i]) {
            this.env = {
              ...this.env,
              ...environmentCommonConfig,
              ...config[group]
            };
            if (isUndefined(this.env.name)) {
              this.env.name = group;
            }
            foundHost = true;
            break;
          }
        }
        if (foundHost) {
          return;
        }
      }
    }
    if (isUndefined(this.env.hostname) && isUndefined(this.env.hostnames)) {
      this.env = {
        ...this.env,
        ...environmentCommonConfig,
        ...environmentProdConfig
      };
      if (isUndefined(this.env.name)) {
        this.env.name = 'prod';
      }
    }
  }
}
