// TODO: remove demo
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ic-translations',
  templateUrl: './translations.component.html',
  encapsulation: ViewEncapsulation.None
})

export class TranslationsComponent implements OnInit {

  dismissBtnTxt: string = '';
  today!: Date;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.dismissBtnTxt = this.translate.instant('notifications.Dismiss');
    this.today = new Date();
  }
}
