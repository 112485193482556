import {Component} from '@angular/core';
import {UserService} from 'components/auth/services/user/user.service';
import {Router} from '@angular/router';
import {SecurityService} from 'components/security/security.service';

@Component({
  template: '<div>Redirecting...</div>'
})

export class SignInComponent {
  constructor(private securityService: SecurityService, private router: Router, private userService: UserService) {
    if (this.userService.getCurrentUser()) {
      this.router.navigate(['/landing']);
    } else {
      this.securityService.redirectTo('signin');
    }
  }
}