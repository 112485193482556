import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';

@Pipe({
  name: 'redirectLink'
})
export class RedirectLinkPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private localeId: string
  ) {}

  transform(value: string) {
    const separator = this.hasSearchParams(value) ? '&' : '?';
    return `${value}${separator}lc=${this.localeId}`;
  }

  private hasSearchParams(url: string) {
    return url.indexOf('?') > -1;
  }

}
