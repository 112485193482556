import { NgModule } from '@angular/core';

import { AuthenticationService } from './services/authentication/authentication.service';
import { AuthorizationService } from './services/authorization/authorization.service';
import { LocalStorageService } from './../common/services/local-storage/local-storage.service';
import { DatasetsVersionService } from './services/datasets-version/datasets-version.service';
import { UserService } from './services/user/user.service';
import {SecurityModule} from 'components/security/security.module';
import {ForbiddenComponent} from 'components/auth/components/forbidden/forbidden.component';
import {RedirectComponent} from 'components/auth/components/redirect/redirect.component';
import {SignInComponent} from 'components/auth/components/sign-in/sign-in.component';
import {SignOutComponent} from 'components/auth/components/sign-out/sign-out.component';
import {AuthRoutingModule} from 'components/auth/services/auth-routes';

@NgModule({
  imports: [SecurityModule, AuthRoutingModule],
  declarations: [SignInComponent, SignOutComponent, ForbiddenComponent, RedirectComponent],
  exports: [],
  providers: [
    AuthenticationService,
    AuthorizationService,
    DatasetsVersionService,
    LocalStorageService,
    UserService,
  ]
})

export class AuthModule {
}