import {Injectable, OnDestroy} from '@angular/core';
import { FolderItemModel } from './models';
import {Observable, Subscription} from 'rxjs';
import { FolderModel } from '@ic/component-lib/src/components/modules/folders/folders.model';
import { FolderWithItemsModel } from 'pages/my-saved/models/folder-with-items.model';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'components/shared/globalData';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import {IcTogglesNgService} from 'components/common/services/ic-toggles-ng/ic-toggles-ng.service';

const baseUrl: string = '/incites-app/';
const bnaBaseUrl: string = '/bna/';
const bnaBaseUrlNginx: string = '/route/bna/';

@Injectable()
export class FoldersRepositoryService implements OnDestroy {

  private serviceUrl: string = `${baseUrl}user/folders`;
  private bnaMicroServiceUrl: string = `${bnaBaseUrl}user/folders`;
  private nginxMicroServiceUrl: string = `${bnaBaseUrlNginx}user/folders`;
  private envConfig: {[key: string]: any}; // tslint:disable-line:no-any
  subscription: Subscription;
  env = this.environmentService.getEnvironment();
  toggles: { [key: string]: string};

  constructor(
    private http: HttpClient,
    private globalData: Globals,
    private environmentService: EnvironmentService,
    private icTogglesNgService: IcTogglesNgService,
  ) {
    this.envConfig = this.environmentService.getEnvironment().splitioTreatment;
    if (!this.globalData.toggleData) {
      this.setSplitIOToggles();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setSplitIOToggles() {
    this.subscription = this.icTogglesNgService.toggles().subscribe((toggles) => {
      this.globalData.toggleData = toggles;
    });
  }

  getSplitIOToggles(toggleValue: string): boolean {
    return (this.globalData?.toggleData && (this.globalData?.toggleData[toggleValue] === 'on'));
  }

  getList(): Promise<FolderModel[]> {
    return this.http.get<FolderModel[]>(this.getUrl())
      .toPromise();
  }

  getFoldersList(folderId?: number|string): Promise<FolderModel[]> {
    if (this.getSplitIOToggles(this.envConfig.IFE_IC_40291_Enable_nginx_route_changes)) {
      return this.http.get<FolderModel[]>(
        `${this.nginxMicroServiceUrl}/${folderId}`
      ).toPromise();
    } else {
      return this.http.get<FolderModel[]>(
        `${this.bnaMicroServiceUrl}/${folderId}`
      ).toPromise();
    }

  }

  create(folder: Partial<FolderModel>): Promise<FolderModel> {
    if (this.getSplitIOToggles(this.envConfig.IFE_IC_40291_Enable_nginx_route_changes)) {
      return this.http.post<FolderModel>(this.nginxMicroServiceUrl, folder).toPromise();
    } else {
      return this.http.post<FolderModel>(this.bnaMicroServiceUrl, folder).toPromise();
    }
  }

  save(folder: FolderModel) {
    if (this.getSplitIOToggles(this.envConfig.IFE_IC_40291_Enable_nginx_route_changes)) {
      return this.http.post(this.nginxMicroServiceUrl + `/${folder.id}`, folder)
        .toPromise();
    } else {
      return this.http.post(this.bnaMicroServiceUrl + `/${folder.id}`, folder)
        .toPromise();
    }
  }

  delete(folderId: number): Promise<{}> {
    if (this.getSplitIOToggles(this.envConfig.IFE_IC_40291_Enable_nginx_route_changes)) {
      return this.http.delete(this.nginxMicroServiceUrl + `/${folderId}`, {}).toPromise();
    } else {
      return this.http.delete(this.bnaMicroServiceUrl + `/${folderId}`, {}).toPromise();
    }
  }

  getItemsList(folderId: number|string): Observable<FolderItemModel[]> {
    return this.http.get<FolderItemModel[]>(`${this.serviceUrl}/${folderId}/items`);
  }

  getItemsListPaginated(
    folderId: number | string,
    skip: number = 0,
    take: number = 25,
    sortBy: string = 'title',
    sortOrder: string = 'desc',
    search: string
  ): Observable<Partial<FolderWithItemsModel>> {
    let nginx = this.getSplitIOToggles(this.envConfig.IFE_IC_40291_Enable_nginx_route_changes);
    let url = nginx ? this.nginxMicroServiceUrl : this.bnaMicroServiceUrl;
    return new Observable<Partial<FolderWithItemsModel>>((observer) => {
      this.http.get<FolderWithItemsModel>(`${url}/${folderId}/items?itemType=AllItems&take=${take}&skip=${skip}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchParam=${search}`)
        .subscribe((items) => {
          const newItem = {
            items: items.items ? this.modifyItemsToOldDataModel(items.items, folderId) : items.items,
            total: items.total
          };
          observer.next(newItem);
          observer.complete();
        }, (e) => observer.error(e));
    });
  }


  modifyItemsToOldDataModel(items: FolderItemModel[], folderId: number|string): FolderItemModel[] {
    let modifiedItems: FolderItemModel[];
    modifiedItems = items.map((item) => {
      switch (item.type) {
        case 'report':
          return {
            ...item,
            id: `dcr_${item.id}`,
            folderId
          };
        case 'dataset':
          return {
            ...item,
            datasetId: item?.metaData?.datasetId,
            id: `dcds_${item.id}`,
            folderId
          };
        case 'groups':
          let returnValue =  {
            ...item,
            entityType: item?.metaData?.entityType,
            compositeEntityKey: item?.metaData?.compositeEntityKey,
            name: item.title,
            folderId
          };
          return returnValue;
          case 'journalcollection':
          if (this.getSplitIOToggles(this.envConfig.isMicroServiceForCollectionEnabled || this.envConfig.IFE_IC_40291_Enable_nginx_route_changes)) {
              const id = item.id;
              const { entityType, journalKeys, journalNames, sourceNameFilterConfig, issnFilterConfig, params } = item?.metaData;
              return {
                ...item,
                id,
                entityType,
                journalKeys,
                journalNames,
                sourceNameFilterConfig,
                issnFilterConfig,
                params,
                title: item.title,
                folderId
              };
            }
            return {
              ...item,
              id: `dbcoll_${item.id}`,
              entityType: item?.metaData.entityType,
              journalKeys: item?.metaData.journalKeys,
              journalNames: item?.metaData.journalNames,
              sourceNameFilterConfig: item?.metaData.sourceNameFilterConfig,
              issnFilterConfig: item?.metaData.issnFilterConfig,
              params: item?.metaData.params,
              title: item.title,
              folderId


            };
        default:
          return item;
      }
    });
    return modifiedItems;
  }

  moveItem(itemId: string, folderId: number|string) {
    itemId = itemId.replace(/[^0-9\.]+/g, '');
    if (this.getSplitIOToggles(this.envConfig.IFE_IC_40291_Enable_nginx_route_changes)) {
      return this.http.post(`${this.nginxMicroServiceUrl}/${folderId}/items/${itemId}`, null);
    } else {
    return this.http.post(`${this.bnaMicroServiceUrl}/${folderId}/items/${itemId}`, null);
  }

  }

  moveGroup(groupId: string, folderId: number|string) {
    if (this.envConfig.IFE_IC_40291_Enable_nginx_route_changes) {
      return this.http.post(`${this.nginxMicroServiceUrl}/${folderId}/items/${groupId}`, null);
    } else {
    return this.http.post(`${this.bnaMicroServiceUrl}/${folderId}/items/${groupId}`, null);
  }

  }

  getSharedItemsList(): Observable<FolderItemModel[]> {
    return this.http.get<FolderItemModel[]>(`${baseUrl}user/shared`);
  }


  getUrl(): string {
    if (this.getSplitIOToggles(this.envConfig.IFE_IC_40291_Enable_nginx_route_changes)) {
      return this.nginxMicroServiceUrl;
    } else {
    return this.bnaMicroServiceUrl;
  }
  }
}