import { Component, ViewEncapsulation } from '@angular/core';
import { VersionRepositoryService } from 'components/app/services/version-repository/version-repository.service';
import {Subject} from 'rxjs';
import {EnvironmentService} from 'components/app/services/environment/environment.service';

@Component({
  selector: 'ic-global-footer',
  templateUrl: './global-footer.component.html',
  encapsulation: ViewEncapsulation.None
})
export class GlobalFooterComponent {
  public copyrightYear: number;
  public version: Subject<string>;
  env = this.environmentService.getEnvironment();
  IS_CDX_FOOTER_ON: boolean = false;

  constructor(
    versionRepository: VersionRepositoryService,
    private environmentService: EnvironmentService,
  ) {
     this.IS_CDX_FOOTER_ON = this.env.feature.cdxHeaderFooter;
    const currentDate = new Date();

    this.copyrightYear = currentDate.getFullYear();
    this.version = versionRepository.get();
  }
}