import { Injectable } from '@angular/core';

@Injectable()
export class LocaleService {
  public readonly fallbackLang = 'en-US';

  href(url: string) {
    let path = url;
    let extraSeparator = '/';

    if (typeof path === 'undefined' || path === null) {
      path = '';
    } else if (path.length > 0 && path[0] === '/') {
      extraSeparator = '';
    }
    if (this.id === 'ru' || this.id === 'zh') {
      return `/${this.id}${extraSeparator}${path}`;
    }
    return path;
  }

  // returns the <html> lang attribute set during build time
  get id(): string {
    const lang = document.documentElement.lang;

    return lang.match(/en-US|ru|zh/) ? lang : this.fallbackLang;
  }
}
