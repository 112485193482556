import { BrowserDetectionService } from 'components/common/services/browser-detection/browser-detection.service';
import { ChangeDetectorRef, Component, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import { finalize, mergeMap, map, tap } from 'rxjs/operators';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import {has, assign} from 'lodash';
import { LogEventService } from 'components/rest/services/log-event/log-event.service';
import { Observable, from, of } from 'rxjs';
import { PopoverModalComponent } from '@ic/component-lib/src/components/modules/popover-modal/popover-modal.component';
import { ReportModel } from 'components/common/interfaces/report.model';
import { ReportPdfGeneratorService } from 'components/common/services/pdf-generator/report-pdf-generator.service';
import { ReportsRepositoryService } from 'components/rest/services/reports-repository/reports-repository.service';
import { TabReportModel } from 'pages/tab-report/interfaces/tab-report.model';
import { TranslateService } from '@ngx-translate/core';
import { WindowRefService } from 'components/common/window/window-ref.service';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'ic-report-share',
  templateUrl: './report-share.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ReportShareComponent {
  @Input() report!: ReportModel|TabReportModel;
  @Output() onView = new EventEmitter();

  @ViewChild(PopoverModalComponent, { static: true }) popoverModal!: PopoverModalComponent;

  public messageMaxLength = 140;
  public shareForm: FormGroup;
  public request: Observable<boolean>|null = null;
  public textInputLimiterOptions = {
    inputId: 'message',
    inputLabel: this.translate.instant('share.Message'),
    inputName: 'message',
    inputPlaceholder: this.translate.instant('share.Message'),
    maxLimit: this.messageMaxLength,
    showLabel: true,
    useCountdown: true,
    warnLimit: this.messageMaxLength - 10
  };
  public message: string = '';
  private routeParams: Params;

  constructor(
    private browserDetectionService: BrowserDetectionService,
    private cd: ChangeDetectorRef,
    private eventsTracker: EventsTrackerService,
    private logEventService: LogEventService,
    private pdfGenerator: ReportPdfGeneratorService,
    private reportsRepository: ReportsRepositoryService,
    private translate: TranslateService,
    private window: WindowRefService,
    private tracker: EventsTrackerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.shareForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ])
    });

    this.route.params.subscribe(params => {
      this.routeParams = params;
    });
  }

  onModalOpen() {
    this.request = null;
    this.shareForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i)
      ])
    });
    this.message = '';
    this.eventsTracker.trackEvent('Report', this.report.title, `${this.report.title} Share`);
  }

  onSubmit() {
    this.share();
  }

  closePopover() {
    this.popoverModal.closePopover();
  }

  onMessageUpdate(message: string) {
    this.message = message;
  }

  messageValidator() {
    return this.message.length <= this.messageMaxLength;
  }

  private share() {

    this.request = this.genParams().pipe(

      tap(() => this.logEventService.send('Share_Action')),
      mergeMap((prms: {[index: string]: string}) => this.reportsRepository.share(this.report.id, prms)),
      finalize(() => {

        setTimeout(() => {
          this.closePopover();
          this.cd.detectChanges();
        }, 4000);
      })
    );
    this.tracker.trackEvent('Reports', 'Share Reports', `${this.report.title}`);
  }

  private genParams(): Observable<{[index: string]: string}> {
    const params: {[index: string]: string} = {
      url: this.getShareUrl(this.report),
      emails: this.shareForm.value.email,
      message: this.message
    };

    if (!this.browserDetectionService.isIE11()) {
      return from(this.pdfGenerator.reportToPdfBase64(this.report)).pipe(
        map((pdfBase64: string) => assign(params, {pdfBase64}))
      );
    }

    return of(params);
  }

  private getShareUrl(report: ReportModel|TabReportModel) {
    if (has(report, 'reports')) {
      return this.getReportUrlEnglish('tabreports', report);
    } else {
      return this.getReportUrl(report as ReportModel);
    }
  }

  getReportUrlEnglish(path: string, report:  ReportModel|TabReportModel) {
    const currentLang = '/' + ((this.routeParams ? this.routeParams.lang : '') || '');
    let origin = this.window.nativeWindow().location.origin;
    const routeHash = this.router.createUrlTree([`/${path}`, report.id], { queryParamsHandling: 'preserve'}).toString();

    if (currentLang === '/') {
      return `${origin}/#${routeHash}`;
    } else {
      return `${origin}/#${routeHash.replace(currentLang,  '')}`;
    }
  }

  private getReportUrl(report: ReportModel) {
    let windowLocation = this.window.nativeWindow().location;
    if (report.institutionProfile) {
      return windowLocation.href;
    }
    return this.getReportUrlEnglish('report', report);
  }

}