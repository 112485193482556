import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MainContainerService {

  private modifiers: string[] = [];

  private modifiersSubject = new BehaviorSubject([...this.modifiers]);

  constructor() {}

  getModifierClassesObservable() {
    return this.modifiersSubject.pipe(
      // tslint:disable-next-line:no-any
      map((classNames: any[]) => {
        return classNames
          .map(className => `ic-main-container--${className}`)
          .join(' ');
      })
    );
  }

  addModifierClass(modifier: string) {
    if (this.modifiers.indexOf(modifier) === -1) {
      this.modifiers.push(modifier);
    }
    this.modifiersSubject.next([...this.modifiers]);
  }

  removeModifierClass(modifier: string) {
    this.modifiers = this.modifiers.filter(className => className !== modifier);
    this.modifiersSubject.next([...this.modifiers]);
  }

}
