import { ChartComponent } from '@ic/component-lib/src/components/modules/chart/chart.component';
import { ChartOptionsModel } from '@ic/charts/src/models/chart.model';
import { cloneDeep, isEqual, toString } from 'lodash';
import { Component, OnInit, Input, DoCheck, ViewChild } from '@angular/core';
import { DiagramLongType } from 'components/diagram/services/diagram-options/diagram-options.config';
import { utilities } from 'components/common/utilities/utilities';
import {
  AdditionalData,
  DiagramOptionsService
} from 'components/diagram/services/diagram-options/diagram-options.service';
import {ReportModel} from 'components/common/interfaces/report.model';
import {Indicator} from 'components/common/interfaces/indicator.interface';

@Component({
  selector: 'ic-rich-tile',
  templateUrl: './rich-tile.component.html'
})
export class RichTileComponent implements DoCheck, OnInit {
  @Input() data: any; // tslint:disable-line:no-any
  @Input() api!: {};
  @Input() tileIndex!: number;
  @Input() report: Partial<ReportModel> = {};
  @Input() dataUpdateInfo: string;

  @ViewChild(ChartComponent, { static: false }) chartComponent!: ChartComponent;

  public options: ChartOptionsModel | undefined;

  public previousData: any; // tslint:disable-line

  constructor(private diagramOptions: DiagramOptionsService) {
  }

  ngOnInit() {
    this.registerApiMethods();
  }

  ngDoCheck() {
    const currentData = cloneDeep(this.data);
    if (!isEqual(currentData, this.previousData)) {
      this.previousData = cloneDeep(this.data);
      this.onDataChange();
    }
  }

  toPngBase64(scale: number = 1) {
    return this.chartComponent.toPngBase64(scale);
  }

  downloadPng(filename: string, scale: number = 2) {
    return this.chartComponent.downloadPng(filename, scale);
  }

  isReady() {
    return !!this.chartComponent;
  }

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // tslint:disable-next-line:no-any
  setAlmaTreemapLabel(visOptions: any) {
    if (this.data.reportId === 'dbr_169' && this.data.tileType === 'tree map' ) {
      let params = utilities.fromJson(this.data.params);
      let indicator = this.data.indicators.find((ic: Indicator) => ic.name === visOptions.dimensions.value);
      let filter = params.request.filters['jrnSourceType'].is;
      if (Array.isArray(filter)) {
        let title =  filter.join(', ');
        let t = 0;
        // tslint:disable-next-line:no-any
        title = title.replace(/, /g, (match: any) => ++t === filter.length - 1 ? ' and ' : match);
        indicator.title = title;
      } else {
        indicator.title = filter;
      }
    }
  }

  onDataChange() {
    let data = this.data;

    if (data && data.value) {
      if (
        data.value.length > 0 || (data.benchmarks && data.benchmarks.items && data.benchmarks.items.length > 0)
      ) {
        let visOptions = utilities.fromJson(data.vis);

        if (!visOptions.type) {
          visOptions.type = data.tileType;
        }

        if (!visOptions.title) {
          visOptions.title = data.tileType;
        }

        let benchmarks = data.benchmarks;
        this.setAlmaTreemapLabel(visOptions);
        let options = this.diagramOptions.getOptions(
          visOptions,
          data.value,
          data.indicators,
          benchmarks && benchmarks.items,
          benchmarks && benchmarks.indicators,
          visOptions.dimensions,
          false,
          { tile: this.data, isPreviewTile: true, dataUpdateInfo : this.dataUpdateInfo } as AdditionalData
        );

        // TODO: temporary hack for incorrect updating series name
        if (options.type as DiagramLongType === 'map chart' || options.type as DiagramLongType === 'map amCharts') {
          options.series!.seriesName = 'hits';
        }

        if (!(options.center && options.center.title)) {
          if (data.displayItems) { // For new system old reports displayItems is used as center title
            options.center = { title: data.displayItems };
          } else {
            const getParmsObject = this.isJsonString(data.params) ? JSON.parse(data.params) : data.params;
            const isClbrorgnameArray = getParmsObject && getParmsObject.request &&
              ((getParmsObject.request.filters.clbrorgname && getParmsObject.request.filters.clbrorgname.is) ||
                (getParmsObject.request.filters.clbrprsnId && getParmsObject.request.filters.clbrprsnId.is) ||
                (getParmsObject.request.filters.clbrlocname && getParmsObject.request.filters.clbrlocname.is) ||
                (getParmsObject.request.filters.clbrfunder && getParmsObject.request.filters.clbrfunder.is ) ||
                (getParmsObject.request.filters.wpclbrprsnId && getParmsObject.request.filters.wpclbrprsnId.is) ||
                (getParmsObject.request.filters.wpclbrorgname && getParmsObject.request.filters.wpclbrorgname.is)
              );
            if (isClbrorgnameArray && isClbrorgnameArray.length > 0) {
              const clbrorgnameList = toString((getParmsObject.request.filters.clbrorgname &&
                getParmsObject.request.filters.clbrorgname.is) || (getParmsObject.request.filters.clbrprsnId && getParmsObject.request.filters.clbrprsnId.is) ||
                (getParmsObject.request.filters.clbrlocname && getParmsObject.request.filters.clbrlocname.is) ||
                (getParmsObject.request.filters.clbrfunder && getParmsObject.request.filters.clbrfunder.is) ||
                (getParmsObject.request.filters.wpclbrprsnId && getParmsObject.request.filters.wpclbrprsnId.is) ||
                (getParmsObject.request.filters.wpclbrorgname && getParmsObject.request.filters.wpclbrorgname.is)
              );
              // For old system  reports clbrorgname is used as center title
              options.center = { title: clbrorgnameList };
            }

          }
        }

        this.options = options;
        this.options!.tileIndex = this.tileIndex;
        this.options!.dashboard = this.report.dashboard;
        this.options!.system = this.report.system;
      }
    }
  }

  private registerApiMethods() {
    if (this.api) {
      Object.assign(this.api, {
        toPngBase64: this.toPngBase64.bind(this)
      });
    }
  }

}
