import { BenchmarkType, DiagramLongType, DiagramType, getDiagramAlternativeType, getDiagramLongType, getDiagramType, isAmChartsType, isD3Type } from './diagram-options.config';
import { MetadataModel, VisualizationSelectedDimensionsModel, VisualizationModel } from '../../interfaces/diagram-options.model';
import { DiagramTransformService } from '../diagram-transform/diagram-transform.service';
import * as _ from 'lodash';
import { Indicator } from '../../../common/interfaces/indicator.interface';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { NumberFormatterService } from 'components/ui/filters/number-formatter/number-formatter.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ChartAxesModel,
  ChartAxisOptionsModel,
  ChartBenchmarkItemModel,
  ChartItemDataModel,
  ChartOptionsModel,
  ChartSeriesModel,
  LegendModel,
} from '@ic/charts/src/models/chart.model';
import { UserService } from 'components/auth/services/user/user.service';
import { ReportTileModel } from 'components/common/interfaces/report-tile.model';
import { AnalysisStateFiltersModel, AnalysisStateIndicatorsModel, RequestParamsModel } from 'pages/analysis/models/analysis-state.model';
import { AnalysisIndicatorModel } from 'pages/analysis/models';
import { AnalysisEntityService } from 'components/common/services/analysis-entity/analysis-entity.service';
import { FilterService } from 'components/analytics/services/filter/filter.service';
import { environmentCommonConfig } from 'components/app/services/environment/environment-common.config';
import {FilterModel, FiltersResponseModel} from 'components/analytics/interfaces/filter.interface';
import { AnalysisAppliedFiltersService } from 'components/common/services/analysis-applied-filters/analysis-applied-filters.service';
import { DiagramPdfGeneratorService } from 'components/common/services/pdf-generator/diagram-pdf-generator.service';
import { MetadataRepositoryService } from 'components/rest/services/metadata-repository/metadata-repository.service';
import { utilities } from 'components/common/utilities/utilities';
import { DiagramModel } from 'components/common/interfaces/diagram.model';
import { isUndefined } from 'lodash';
import { EventsTrackerService } from 'components/common/services/events-tracker/events-tracker.service';
import {DatasetsRepositoryService} from 'components/rest/services/datasets-repository/datasets-repository.service';
import {DatasetModel} from 'components/rest/services/datasets-repository/models';
import { ActivationEnd, Params, Router} from '@angular/router';
export interface AdditionalData {
  tile: ReportTileModel | undefined;
  datasetTitle: string;
  dataUpdateInfo: string;
  filters: AnalysisStateFiltersModel;
  _requestParams: RequestParamsModel;
  indicators: AnalysisStateIndicatorsModel;
  datasetLabel: string;
  indicatorsLabel: string;
  isPreviewTile?: boolean;
}
@Injectable()
export class DiagramOptionsService {

  datasetLabel: string;
  indicatorsLabel: string;
  dataUpdateInfo: string;
  datasets: DatasetModel[];
  tilesFiltersSet: {[key: string]: FiltersResponseModel} = {};
  routeParams: Params;
  constructor(
    private diagramTransform: DiagramTransformService,
    private environmentService: EnvironmentService,
    private numberFormatter: NumberFormatterService,
    private translate: TranslateService,
    private userService: UserService,
    private entityService: AnalysisEntityService,
    private filterService: FilterService,
    private appliedFiltersService: AnalysisAppliedFiltersService,
    private pdfGenerator: DiagramPdfGeneratorService,
    private metadataRepository: MetadataRepositoryService,
    private eventsTracker: EventsTrackerService,
    private datasetsRepositoryService: DatasetsRepositoryService,
    private router: Router
  ) {
    this.datasetLabel = this.translate.instant('analytics.explore.Dataset');
    this.indicatorsLabel = this.translate.instant('analytics.explore.Indicators');
    this.router.events.subscribe(val => {
      if (val instanceof ActivationEnd) {
        if (Object.keys(val.snapshot.params).length > 0) {
          this.routeParams = val.snapshot.params;
        } else {
          this.routeParams = val.snapshot.firstChild?.params as Params;
        }
      }
    });
  }

  getLinearVisType(currentVizType: DiagramLongType, getAmchartVisType: boolean) {
    if (['multiIndicatorLine chart', 'multiIndicatorLine amCharts'].includes(currentVizType)) {
      return getAmchartVisType ? 'multiIndicatorLine amCharts' : 'multiIndicatorLine chart';
    } else if (['line chart', 'line amCharts'].includes(currentVizType)) {
      return getAmchartVisType ? 'line amCharts' : 'line chart';
    } else if (['bar chart', 'bar amCharts'].includes(currentVizType)) {
      return getAmchartVisType ? 'bar amCharts' : 'bar chart';
    } else if (['heat map', 'heatmap amCharts'].includes(currentVizType)) {
      return getAmchartVisType ? 'heatmap amCharts' : 'heat map';
    } else if (['pie', 'pie amCharts'].includes(currentVizType)) {
      return getAmchartVisType ? 'pie amCharts' : 'pie';
    } else {
      return getAmchartVisType ? 'period amCharts' : 'period chart';
    }
  }

  // tslint:disable-next-line:no-any
  toggleMapBasedOnPOC(POC: string, diagramOptions: any) {
    const diagramAlternativeType = getDiagramAlternativeType(diagramOptions.type) as DiagramType;
    if (this.environmentService.getEnvironment().feature[POC]) {
      if (isD3Type(diagramOptions.type)) {
        diagramOptions.type = diagramAlternativeType;
        if (diagramOptions.type === 'linearAmcharts') {
          diagramOptions.visualizationType = this.getLinearVisType(diagramOptions.visualizationType,
          true) as DiagramLongType;
        } else { diagramOptions.visualizationType = getDiagramLongType(diagramAlternativeType) as DiagramLongType;
      }
      } else if (isAmChartsType(diagramOptions.type)) {
        if (diagramOptions.type === 'linearAmcharts') {
          diagramOptions.visualizationType = this.getLinearVisType(diagramOptions.visualizationType,
            true) as DiagramLongType;
        } else { diagramOptions.visualizationType = getDiagramLongType(diagramOptions.type) as
           DiagramLongType; }
        }
      } else {
        if (isAmChartsType(diagramOptions.type)) {
          diagramOptions.type = diagramAlternativeType;
          if (diagramOptions.type === 'linearAmcharts') {
            diagramOptions.visualizationType = this.getLinearVisType(diagramOptions.visualizationType,
            false) as DiagramLongType;
        } else {
         diagramOptions.visualizationType = getDiagramLongType(diagramAlternativeType) as DiagramLongType;
      }
    }
  }

    return diagramOptions;
  }

  // tslint:disable-next-line:no-any
  mapDiagramOption(diagramOptions: any, visualizationType?: DiagramLongType) {
    switch (diagramOptions.type) {
      case 'map' as DiagramType:
      case 'map amCharts' as DiagramType:
        return this.toggleMapBasedOnPOC('amchartsPOC', diagramOptions);
      case 'treemap' as DiagramType:
      case 'treemapAmcharts' as DiagramType:
        return this.toggleMapBasedOnPOC('treemapPOC', diagramOptions);
      case 'bar' as DiagramType:
      case 'barAmcharts' as DiagramType:
        return this.toggleMapBasedOnPOC('barAmchartsPOC', diagramOptions);
      case 'heatmap' as DiagramType:
      case 'heatmapAmcharts' as DiagramType:
        return this.toggleMapBasedOnPOC('heatmapAmchartsPOC', diagramOptions);
      case 'linear' as DiagramType:
      case 'linearAmcharts' as DiagramType:
        if (['multiIndicatorLine chart', 'multiIndicatorLine amCharts'].includes(visualizationType as DiagramLongType)) {
          return this.toggleMapBasedOnPOC('multiIndicatorTrendGraphPOC', diagramOptions as DiagramLongType);
        } else if (['line chart', 'line amCharts'].includes(visualizationType as DiagramLongType)) {
          return this.toggleMapBasedOnPOC('trendGraphPOC', diagramOptions);
        } else {
          return this.toggleMapBasedOnPOC('fiveYearTrendGraphPOC', diagramOptions);
        }
      case 'pie' as DiagramType:
      case 'pieAmcharts' as DiagramType:
        return this.toggleMapBasedOnPOC('pieAmchartsPOC', diagramOptions);
      default:
      return diagramOptions;
    }
  }

  getOptions(
    visualizationConfig: VisualizationModel,
    items: {}[],
    indicators: Indicator[],
    benchmarksConfig?: { name: BenchmarkType, value: Object[] }[],
    benchmarkIndicators?: Indicator[],
    selectedDimensions?: VisualizationSelectedDimensionsModel,
    maybeNoData?: boolean,
    additionalData?: AdditionalData
  ): ChartOptionsModel {
    const optionalDiagramParams = ['benchGroupBy', 'colors', 'groupBy', 'multiDimensional', 'padding', 'size',
      'textSize', 'textSizeUnits', 'tooltip', 'center', 'extraType', 'orient', 'title', 'itemsCount',
      'transformSeries', 'zoomable', 'axis', 'segment', 'resolvedDimensions', 'selectedDimensions', 'noBenchmark', 'hideCounter'];
    const visualizationType = visualizationConfig.type;
    const diagramConfig = _.merge(this.getDefaultOptions(visualizationConfig.type), visualizationConfig);
    const diagramOptionalSettings = _.pick(diagramConfig, optionalDiagramParams);
    const type = getDiagramType(visualizationConfig.type) || diagramConfig.type;
    const zoomable = type === 'map' ? diagramConfig.zoomable : undefined;
    const zoomOptions = type === 'map' ? diagramConfig.zoomOptions : undefined;
    const legend = this.initLegend() as LegendModel;
    let axes: ChartAxisOptionsModel[] | undefined;
    let axis: ChartAxesModel | undefined;
    let noData: boolean | undefined = maybeNoData;
    let series = diagramConfig.series ? _.cloneDeep(diagramConfig.series) : undefined;
    let benchmarks;

    if (series) {
      series = this.extendWithIndicators(series, indicators);

      const isMultipleDimensions = selectedDimensions && Array.isArray(selectedDimensions.value);

      if ((type === 'radar' || type === 'linear' || type === 'linearAmcharts') && isMultipleDimensions) {
        axes = this.initMultiDimensionAxes(indicators, selectedDimensions!);
      } else {
        axis = this.initSingleDimensionAxes(diagramConfig.axis!, series!.metadata, type);
      }

      series!.data = this.prepareData(type, visualizationType, items, indicators, selectedDimensions);

      if (type === 'radar') {
        series = this.updateRadarSeriesdata(series);
      }

      series!.metadata = this.prepareMetadata(series!.metadata, type, indicators, selectedDimensions!);
    }

    if (benchmarksConfig) {
      benchmarks = this.extendWithIndicators(_.cloneDeep(diagramConfig.series), benchmarkIndicators!);
      benchmarks!.data = this.prepareData(
        type,
        visualizationType,
        this.normalizeBenchmarks(benchmarksConfig),
        indicators,
        selectedDimensions
      );

      if (type === 'radar') {
        benchmarks = this.updateRadarSeriesdata(benchmarks);
      }
    }

    noData = typeof maybeNoData === 'undefined' ?
      this.hasNoData(series, selectedDimensions) :
      maybeNoData;
    let diagramOptions = _.extend(
      diagramOptionalSettings,
      { axis, benchmarks, legend, series, type, axes, noData, zoomable, zoomOptions, visualizationType }
    );

    diagramOptions = this.mapDiagramOption(diagramOptions, visualizationType);

    if (this.userService.currentUser && this.userService.currentUser!.customerCountry) {
      diagramOptions.customerCountry = this.userService.currentUser!.customerCountry;
    }

    if (isAmChartsType(diagramOptions.type)) {
      diagramOptions.amchartsLicenseKeys = environmentCommonConfig.amchartsLicenseKeys;
      if (diagramOptions.series && diagramOptions.series.data && diagramOptions.series.data.length > 0 ) {
        diagramOptions.series.data = this.escapeCharacters(diagramOptions.series.data);
      }
    }

    if (isAmChartsType(diagramOptions.type) && additionalData) {
      diagramOptions.isPreviewTile = additionalData.isPreviewTile;
      diagramOptions.entityId = this.routeParams?.entityId;
      diagramOptions.exportable = true;
      diagramOptions.datasetLabel = this.datasetLabel;
      diagramOptions.indicatorsLabel = this.indicatorsLabel;
      let isSbjName = (diagramOptions && diagramOptions.series && diagramOptions.series.metadata
        && diagramOptions.series.metadata.name && diagramOptions.series.metadata.name.name === 'sbjName') ? true : false;
      if (diagramOptions.type === 'treemapAmcharts' || diagramOptions.type === 'treemapAmchartsSDG') {
        if (additionalData.isPreviewTile) {
          try {
            // @ts-ignore
            if (isSbjName && JSON.parse(additionalData.tile.params).request.filters.schema.is === environmentCommonConfig.SDG) {
              diagramOptions.type = 'treemapAmchartsSDG';
              diagramOptions.visualizationType = 'treemap amchartsSDG';
            } else {
              diagramOptions.type = 'treemapAmcharts';
              diagramOptions.visualizationType = 'treemap amCharts';
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          if (isSbjName && additionalData.filters && additionalData.filters.values &&
            additionalData.filters.values.schema && // @ts-ignore
            additionalData.filters.values.schema.is.toString() === environmentCommonConfig.SDG) {
            diagramOptions.type = 'treemapAmchartsSDG';
            diagramOptions.visualizationType = 'treemap amchartsSDG';
          } else {
            diagramOptions.type = 'treemapAmcharts';
            diagramOptions.visualizationType = 'treemap amCharts';
          }
        }
      }

      const entityId = this.routeParams?.entityId;
      const fileName = additionalData!.tile ? additionalData!.tile!.title as string :
        `${additionalData!.datasetTitle} ${this.entityService.getLocalizedTitle(entityId)} ${diagramOptions.title}`;

      diagramOptions.fileName = fileName;

      if (additionalData!.tile) {
        diagramOptions.title = additionalData!.tile!.title;
        diagramOptions.subtitle = additionalData!.tile!.subtitle;
      } else {
        diagramOptions.subtitle = '';
      }
      diagramOptions.triggerSnowPlow = () => {
        this.eventsTracker.trackEvent('Explore', 'Download chart', `${diagramOptions.title} download`);

      };

      if (diagramOptions.isPreviewTile) {
        diagramOptions.footerData = async () => {
          return this.footerDataForPreviewTile(additionalData, diagramOptions);
        };
      } else {
        // @ts-ignore
        const filtersAllArray: FilterModel[] = this.filterService.getFilterSummary(additionalData!.filters.filters, additionalData!._requestParams.filters);
        const appliedFilters = this.appliedFiltersService.getAppliedComponentParams(additionalData.filters);
        let getAppliedFilters = _.cloneDeep(appliedFilters.appliedFilters);
        if (this.metadataRepository.currentEntity === 'department') {
          if (getAppliedFilters.find((val: { name: string; }) => val.name === 'departments')) {
           let index = getAppliedFilters.findIndex((val: { name: string; }) => val.name === 'departments');
           getAppliedFilters[index].appliedValues.is = Object.values(getAppliedFilters[index].appliedValues.is as unknown as {}) as unknown as [];
         }
         const keysToRemove = ['orgname', 'orgtype', 'deptHierarchy'];
         getAppliedFilters = getAppliedFilters.filter((obj: { name: string; }) => !keysToRemove.includes(obj.name));
       }
        // @ts-ignore
        diagramOptions.sbjName = getAppliedFilters.find(filter => filter.name === 'sbjname');
        const filteredValue: any[] = getAppliedFilters.map((appliedFilter: { title: string; }) => { // tslint:disable-line: no-any
          return filtersAllArray.filter(filterData => appliedFilter.title === filterData.title);
        });
        diagramOptions.footerData = () => {
          const footerData = {
            datasetTitle: additionalData!.datasetTitle,
            dataUpdateInfo: additionalData!.dataUpdateInfo,
            // @ts-ignore
            filtersSummary: _.flattenDeep(filteredValue),
            // @ts-ignore
            indicatorsTitles: this.getIndicatorsTitles(additionalData!.indicators.indicators, selectedDimensions as VisualizationDimensionsModel),
            datasetLabel: additionalData!.datasetLabel,
            indicatorsLabel: additionalData!.indicatorsLabel
          };
          return this.generateFooterText(footerData, diagramOptions);
        };

        diagramOptions.filtersSummary = _.flattenDeep(filteredValue);
      }
    }
    return _.omitBy(diagramOptions, _.isNil) as ChartOptionsModel;
  }

  escapeCharacters(data: ChartItemDataModel[]) {
    data.forEach((item, index) => {
      data[index] = Object.keys(item).reduce((acc, key) => {
        if (typeof(item[key]) === 'string' && /\[|\]|{|}/.test(item[key])) {
          acc[key] = item[key].replace(/\[/g, '[[').replace(/\]/g, ']]').replace(/{/g, '{{').replace(/}/g, '}}');
        } else {
          acc[key] = item[key];
        }
        return acc;
      }, {} as ChartItemDataModel);
    });

    return data;
  }
  updateRadarSeriesdata(series: ChartSeriesModel | undefined) {
    series!.data.forEach(dataItem => {
      if (!dataItem.name && dataItem['indicator-name']) {
        dataItem.name = dataItem['indicator-name'];
      }

      if (!dataItem.value && dataItem['indicator-value']) {
        if (typeof dataItem['indicator-value'] === 'object') {
          dataItem.value = dataItem['indicator-value'].value;
        } else {
          dataItem.value = dataItem['indicator-value'];
        }
      }
    });
    return series;
  }

  // tslint:disable-next-line:no-any
  private generateFooterText(footerData: any, diagramOptions: Partial<ChartOptionsModel>) {
    if (diagramOptions.type !== 'heatmapAmcharts') {
      let filterSummaryArray = diagramOptions.isPreviewTile ? footerData.filters : footerData.filtersSummary;
      const filterSummary = filterSummaryArray.reduce((acc: string,
        item: { [key: string]: string }) => {
        acc += `[bold]${item.title}[/] : [font-weight: 400]${item.value.toString()}.[/] `;
        return acc;
      }, '');
      const indicatorSummary = `[bold]${diagramOptions.indicatorsLabel}[/] : ` +
        `${footerData.indicatorsTitles.toString()}.`;
      const dataSetSummary = `[bold]${diagramOptions.datasetLabel}[/] : ${footerData.datasetTitle}`;
      const dataUpdateInfo = `[font-style: italic]${footerData.dataUpdateInfo}`;
      return `${indicatorSummary}  ${filterSummary} ${dataSetSummary} \n ${dataUpdateInfo}`;
    }
  }

  private getIndicatorsTitles(
    indicators: AnalysisIndicatorModel[],
    selectedDimensions: VisualizationSelectedDimensionsModel
  ): string[] {
    const findIndicatorTitle = (name: string) => {
      const indicator = indicators.find((ind) => ind.name === name);

      return indicator && indicator.title;
    };
    const selectedIndicatorsNames = _.flatten(_.values(selectedDimensions));
    const selectedIndicatorsTitles = selectedIndicatorsNames.map(findIndicatorTitle);
    const cleanIndicatorsTitles = _.compact(selectedIndicatorsTitles) as string[];

    return cleanIndicatorsTitles.length > 0 ? cleanIndicatorsTitles : ['Web of Science Documents'];
  }

  private getDefaultOptions(type: DiagramLongType) {
    let options: Partial<ChartOptionsModel> = {
      series: { data: [], metadata: {} },
      metadata: {},
    };

    switch (type) {
      case 'line chart':
      case 'line amCharts':
      case 'period chart':
      case 'period amCharts':
      case 'multiIndicatorLine chart':
      case 'multiIndicatorLine amCharts':
        options.groupBy = 'key';
        options.benchGroupBy = 'name';
        options.axis = {
          x: {
            // an approximate value, charting library will not limit max ticks count to exactly 8 ticks
            maxTicksCount: 8
          },
          y: {
            removeNegativeTicks: true
          }
        };
        options.originalType = type;
        break;
      case 'scatterplot chart':
        options.groupBy = 'key';
        options.benchGroupBy = 'name';
        options.axis = {
          x: {
            // an approximate value, charting library will not limit max ticks count to exactly 8 ticks
            maxTicksCount: 8
          }
        };
        break;
      case 'map amCharts':
      case 'map chart':
        options.series = {
          seriesName: 'hits',
          data: [],
          metadata: {}
        };

        if (this.environmentService.getEnvironment().feature.geoMapZoom) {
          options.zoomable = true;
        }
        break;
      case 'bar chart':
      case 'bar amCharts':
        options.orient = 'horizontal';
        options.axis = {
          name: {
            showTitle: false
          }
        };
        break;
      case 'pie amCharts':
      case 'one2many':
        options.center = {
          show: true
        };
        break;
      case 'many2many':
        options.transformSeries = this.diagramTransform.many2many;
        options.segment = {
          padding: 0.01
        };
        break;
      case 'radar chart':
        options.groupBy = 'title';
        options.benchGroupBy = 'title';
        break;
      case 'linearBar chart':
        options.groupBy = 'key';
        options.benchGroupBy = 'name';
        options.axis = {
          x: {
            maxTicksCount: 8
          }
        };
        break;
      case 'linearClustered chart':
        options.groupBy = 'key';
        options.benchGroupBy = 'name';
        options.axis = {
          x: {
            maxTicksCount: 8
          }
        };
        break;
      case 'impactprofile':
        options.axis = {
          y: {
            title: this.translate.instant('diagram.Percentage of output for the given time period'),
          }
        };
        break;
      default:
    }

    return options;
  }

  private extendWithIndicators(series: ChartSeriesModel | undefined, indicators: Indicator[]) {
    if (!series) { return; }

    const skip = ['seriesName', 'data', 'metadata'];
    const keys = Object.keys(series);
    const metadata: MetadataModel = series.metadata = {};

    keys.forEach((key) => {
      if (!skip.includes(key) && series[key] !== '') {
        const value = series[key];
        const indicator = _.find(indicators, { name: value });

        if (indicator) {
          metadata[key] = indicator;
        }

        series[key] = (d: ChartItemDataModel) => {
          if (d[value] && typeof d[value].value !== 'undefined') {
            return d[value].value;
          }
          return d[value];
        };
      }
    });

    return series;
  }

  mapMoundDefinedIndicator(foundDefinedIndicator: boolean, foundData: boolean) {
    if (!foundDefinedIndicator) {
      return false;
    }
    return !foundData;
  }

  private hasNoData(
    series: ChartSeriesModel | undefined,
    selectedDimensions: VisualizationSelectedDimensionsModel | undefined
  ) {
    if (typeof selectedDimensions === 'undefined') {
      return true;
    }
    const values: string[] = <string[]>selectedDimensions.value;
    if (!series) { return true; }
    if (typeof values === 'object') { return false; }

    const skip = ['seriesName', 'data', 'metadata'];
    const keys = Object.keys(series);
    let foundData = false;
    let foundDefinedIndicator = false;

    keys.forEach((key) => {
      if (!skip.includes(key) && series[key] !== '') {
        if (series.data) {
          series.data.forEach((datum) => {
            if (typeof datum[values] !== 'undefined') {
              foundDefinedIndicator = true;
              if (datum[values] !== null && datum[values] !== 0) {
                foundData = true;
              }
            }
          });
        }
      }
    });

    return this.mapMoundDefinedIndicator(foundDefinedIndicator, foundData);
  }

  private initMultiDimensionAxes(
    indicators: Indicator[],
    selectedDimensions: VisualizationSelectedDimensionsModel
  ) {
    const values: string[] = <string[]>selectedDimensions.value;
    const axes: ChartAxisOptionsModel[] = values.map((indicatorName) => {
      const indicator: Indicator = <Indicator>_.find(indicators, { name: indicatorName });
      const axis: ChartAxisOptionsModel = {
        title: indicator.title,
        name: indicator.name,
        type: indicator.type,
        unit: indicator.unit,
        currentlanguage: this.translate.currentLang,
        suffix: {
          K: this.translate.instant('diagram.K'),
          M: this.translate.instant('diagram.M'),
          B: this.translate.instant('diagram.B')
        },
        formatter: (value: number) => {
          let formattedValue = this.numberFormatter.format(value, indicator);
          if (this.isNumeric(formattedValue) && indicator.unit === undefined && formattedValue < 100) {
            formattedValue = (Math.round(value * 100) / 100).toFixed(2);
          }
          if (formattedValue !== 'n/a' && axis.name === 'grantAward') {
            return Math.abs(Number(formattedValue)) >= 1000000000
              ? (this.translate.currentLang === 'zh')
                ? (Math.abs(Number(formattedValue)) / 100000000).toFixed(2) + this.translate.instant('diagram.B')
                : (Math.abs(Number(formattedValue)) / 1000000000).toFixed(2) + this.translate.instant('diagram.B')
              // Six Zeroes for Millions
              : Math.abs(Number(formattedValue)) >= 1000000 && Math.abs(Number(formattedValue)) < 1000000000
                ? (Math.abs(Number(formattedValue)) / 1000000).toFixed(2) + this.translate.instant('diagram.M')
                // Three Zeroes for Thousands
                : Math.abs(Number(formattedValue)) >= 1000 && Math.abs(Number(formattedValue)) < 1000000
                  ? (Math.abs(Number(formattedValue)) / 1000).toFixed(2) + this.translate.instant('diagram.K')
                  : Math.abs(Number(formattedValue));
          }
          return formattedValue;
        }
      };

      if (indicator.unit === 'score') {
        axis.min = 0;
        axis.max = 100;
      }

      return axis;
    });

    return axes;
  }

  private initSingleDimensionAxes(axisConfig: ChartAxesModel, metadata: MetadataModel, type: string) {
    const axis: ChartAxesModel = axisConfig || {};
    const keys = Object.keys(metadata);

    keys.forEach((key) => {
      const indicator = metadata[key];

      axis[key] = axis[key] || {};
      axis[key].title = axis[key].title || indicator.title;
      axis[key].currentlanguage = this.translate.currentLang;
      axis[key].unit = indicator.unit,
        axis[key].suffix = {
          K: this.translate.instant('diagram.K'),
          M: this.translate.instant('diagram.M'),
          B: this.translate.instant('diagram.B')
        };
      axis[key].formatter = (value: number, d: { metadata: MetadataModel }) => {
        const currentIndicator = d && d.metadata && d.metadata[key];
        let formattedValue = this.numberFormatter.format(value, currentIndicator || indicator);

        if (this.isNumeric(formattedValue) && indicator.unit === undefined && formattedValue < 100) {
          formattedValue = (Math.round(value * 100) / 100).toFixed(2);
        }
        if (formattedValue !== 'n/a' && axis[key].indicatorName === 'grantAward' && type !== 'treemapAmcharts') {
          return Math.abs(Number(formattedValue)) >= 1000000000
            ? (this.translate.currentLang === 'zh')
              ? (Math.abs(Number(formattedValue)) / 100000000).toFixed(2) + this.translate.instant('diagram.B')
              : (Math.abs(Number(formattedValue)) / 1000000000).toFixed(2) + this.translate.instant('diagram.B')
            // Six Zeroes for Millions
            : Math.abs(Number(formattedValue)) >= 1000000 && Math.abs(Number(formattedValue)) < 1000000000
              ? (Math.abs(Number(formattedValue)) / 1000000).toFixed(2) + this.translate.instant('diagram.M')
              // Three Zeroes for Thousands
              : Math.abs(Number(formattedValue)) >= 1000 && Math.abs(Number(formattedValue)) < 1000000
                ? (Math.abs(Number(formattedValue)) / 1000).toFixed(2) + this.translate.instant('diagram.K')
                : Math.abs(Number(formattedValue));
        }
        return formattedValue;
      };
      axis[key].indicatorName = indicator.name;
      _.extend(axis[key], this.getAxisOptions(indicator));
    });

    return axis;
  }

  private initLegend(): Partial<LegendModel> {
    return {
      lessString: this.translate.instant('diagram.Less'),
      moreString: this.translate.instant('diagram.More'),
      boxSizeString: this.translate.instant('diagram.Box size indicates number of'),
      unitsString: this.translate.instant('diagram.Units'),
    };
  }

  private isNumeric(n: number) {
    return _.isNumber(n) && isFinite(n);
  }

  private getAxisOptions(metadata: MetadataModel) {
    let options = {};
    if (metadata.type === 'number') {
      switch (metadata.unit) {
        case 'percentage':
          options = _.extend(options, { minValue: 0, maxValue: 100, step: 1 });
          break;
        case 'year':
          options = _.extend(options, { step: 1 });
          break;
        case 'rank':
        case 'count':
          options = _.extend(options, { minValue: 0, step: 1 });
          break;
        default:
      }
    } else {
      options = _.extend(options, { minValue: 0, step: 0.001 });
    }
    return options;
  }

  private prepareData(
    type: string,
    originalType: string,
    data: {}[],
    indicators: Indicator[],
    selectedDimensions?: VisualizationSelectedDimensionsModel,
  ): ChartItemDataModel[] {
    let prepared = _.cloneDeep(data);
    if (type === 'radar') {
      prepared = this.diagramTransform.radar(data, indicators.filter((indicator) => {
        return _.includes(selectedDimensions!.value, indicator.name);
      }));
    } else if (type === 'linear') {
      if (originalType === 'multiIndicatorLine chart') {
        prepared = this.prepareDataForMultiIndicatorLine(data, indicators, selectedDimensions!);
      }
    }


    return prepared as ChartItemDataModel[];
  }

  private prepareDataForMultiIndicatorLine(
    data: {}[],
    indicators: Indicator[],
    selectedDimensions: VisualizationSelectedDimensionsModel
  ) {
    let selectedIndicators = indicators.filter((indicator) => {
      if (typeof selectedDimensions === 'undefined') {
        return true;
      }
      return _.includes(selectedDimensions.value, indicator.name);
    });
    let selectedIndicatorNames = selectedIndicators.map((indicator) => {
      return indicator.name;
    });

    return data.filter((item: { [key: string]: string | number | null }) => {
      let hasNonNull = false;

      for (let i = 0; i < selectedIndicatorNames.length; i += 1) {
        let name = selectedIndicatorNames[i];
        if (typeof item[name] !== 'undefined' && item[name] !== null) {
          hasNonNull = true;
          break;
        }
      }
      return hasNonNull;
    });
  }

  private prepareMetadata(
    metadata: MetadataModel,
    type: string,
    indicators: Indicator[],
    selectedDimensions: VisualizationSelectedDimensionsModel
  ) {
    if (type === 'radar') {
      indicators
        .filter((indicator) => _.includes(selectedDimensions.value, indicator.name))
        .forEach(item => metadata[item.name] = item);
    }

    return metadata;
  }

  private normalizeBenchmarks(benchmarksConfig: { name: BenchmarkType, value: Object[] }[]): ChartBenchmarkItemModel[] {
    return benchmarksConfig.reduce((arr, bmk) => {
      arr.push(
        ...bmk.value.map(value => _.extend({ benchmarkType: bmk.name }, value) as ChartBenchmarkItemModel)
      );

      return arr;
    }, [] as ChartBenchmarkItemModel[]);
  }
  async footerDataForPreviewTile(additionalData: AdditionalData, diagramOptions: { [key: string]: string }) {
    let params = {} as RequestParamsModel;
    try {
      params = utilities.fromJson(additionalData!.tile!.params);
    } catch (error) {
      console.log(error);
    }
    if (Object.keys(params).length > 0) {
      const appliedFilters = params.request.filters;
      if (!this.dataUpdateInfo) {
        if (additionalData.dataUpdateInfo) {
          this.dataUpdateInfo = additionalData.dataUpdateInfo;
        } else {
          this.dataUpdateInfo = await this.pdfGenerator['getDataUpdateInfo']();
        }

      }

      if (!this.datasets) {
        this.datasets = await this.datasetsRepositoryService.getList();
      }

      let dataset = this.datasets.find((dt) => dt.datasetId === params.datasetId.toString());
      let datasetTitle = dataset ? dataset.title : undefined;
      let filterResponse: FiltersResponseModel;
      if (!Object.keys(this.tilesFiltersSet).includes(additionalData!.tile!.id)) {
        this.tilesFiltersSet[additionalData!.tile!.id] = await this.metadataRepository.getFilters(params.datasetId, params.entity, additionalData!.tile!.id);
      }

      filterResponse = this.tilesFiltersSet[additionalData!.tile!.id];

      // tslint:disable-next-line:no-any
      let filters = isUndefined(filterResponse) ? [] : filterResponse.filters;
      const filterNames = Object.keys(appliedFilters);
      // @ts-ignore
      filters = filters.reduce((acc: { [key: string]: string }[], filter: { [key: string]: string }) => {
        if (filterNames.includes(filter.name)) {
          acc.push({title: filter.title, value: appliedFilters[filter.name][Object.keys(appliedFilters[filter.name])[0]]});
        }
        return acc;
      }, []);
      const {title, subtitle} = additionalData!.tile as ReportTileModel;
      const indicatorsTitles = this.pdfGenerator['getIndicatorsTitles'](additionalData!.tile as ReportTileModel);
      const tileData = {title, subtitle, filters, datasetTitle , indicatorsTitles, dataUpdateInfo : this.dataUpdateInfo} as unknown as DiagramModel;
      return this.generateFooterText(tileData, diagramOptions);
    } else {
      return '';
    }
  }
}