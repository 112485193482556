import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {SignInComponent} from 'components/auth/components/sign-in/sign-in.component';
import {SignOutComponent} from 'components/auth/components/sign-out/sign-out.component';
import {RedirectComponent} from 'components/auth/components/redirect/redirect.component';
import {ForbiddenComponent} from 'components/auth/components/forbidden/forbidden.component';

const routes: Routes = [
  { path: 'signin', component: SignInComponent },
  { path: 'signout', component: SignOutComponent },
  { path: 'redirect', component: RedirectComponent },
  { path: 'forbidden', component: ForbiddenComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }