import { BenchmarkModel } from 'components/analytics/interfaces/benchmark.interface';
import { FiltersResponseModel } from 'components/analytics/interfaces/filter.interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IndicatorResponseModel } from 'components/common/interfaces/indicator.interface';
import { Injectable } from '@angular/core';
import {map, shareReplay} from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { omit, omitBy, isNil } from 'lodash';
import { utilities } from 'components/common/utilities/utilities';
import { VisualizationModel } from 'components/diagram/interfaces/diagram-options.model';

const baseUrl: string = '/incites-app/';

@Injectable()
export class MetadataRepositoryService {
  private serviceUrl: string = `${baseUrl}explore/`;
  private $openWosModal = new Subject();
  public $openWosModalObs = this.$openWosModal.asObservable();
  public currentEntity: string;

  constructor(private http: HttpClient) {}

  getIndicators(entity: string, datasetId: string): Observable<IndicatorResponseModel> {
    this.currentEntity = entity;
    return this.http.get<IndicatorResponseModel>(`${this.serviceUrl}${datasetId}/${entity}/metadata/indicators`);
  }

  getFiltersRx(datasetId: string, entity: string, filterAggId: string): Observable<FiltersResponseModel> {
    return this.http.get<FiltersResponseModel>(
        `${this.serviceUrl}${datasetId}/${entity}/${filterAggId}/metadata/filters`
      );
  }

  getFilters(datasetId: string, entity: string, filterAggId: string): Promise<FiltersResponseModel> {
    return this.getFiltersRx(datasetId, entity, filterAggId).toPromise();
  }

  getTabReportFilters(reportId: string, datasetId: string, esci: boolean ) {
    const esciStr = esci ? 'esci' : 'default';

    return this.http.get(
      `${baseUrl}tabreports/${reportId}/${datasetId}/${esciStr}/metadata/filters`);
  }

  getVisualizations(
    datasetId: string,
    entity: string,
    indicators: string[],
    filters: string[],
    filterAggId?: string
  ): Observable<VisualizationModel[]> {

    const url = `${this.serviceUrl}${datasetId}/${entity}/metadata/visualizations`;
    const params = {indicators, filters, filterAggId, queryDataCollection : null};

    return this.http.post<VisualizationModel[]>(url, params).pipe(
      map((response) => {
        return response.map((viz) => {
          if ((viz.type === 'map chart' || viz.type === 'map amCharts') && typeof viz.dimensions === 'undefined') {
            viz.dimensions = [{ indicators: ['wosDocuments'], name: 'value' }];
          }
          return viz;
        });
      })
    );
  }

  getOrganizations(match: string): Promise<string[]> {
    const httpParams = new HttpParams({ fromObject: { q: match } });

    return this.http.get<string[]>(
      `${this.serviceUrl}filter/organizations`,
      { params: httpParams }
    ).toPromise();
  }

  getOrganizationsWithProfiles(match: string = ''): Promise<string[]> {
    const httpParams = new HttpParams({ fromObject: { q: match } });

    return this.http.get<string[]>(
      `${this.serviceUrl}filter/organizationswithprofiles`,
      { params: httpParams }
    ).toPromise();
  }

  getBenchmarks(datasetId: string, entity: string, filterAggId?: string): Observable<BenchmarkModel[]> {
    filterAggId = filterAggId !== 'undefined' ? filterAggId : undefined;
    const params = new HttpParams({ fromObject: omitBy({filterAggId} as {}, isNil)});

    return this.http.get<BenchmarkModel[]>(
      `${this.serviceUrl}${datasetId}/${entity}/benchmarks`,
      {params}
    );
  }

  getTransitions(datasetId: string, entity: string, queryDataCollection: string) {
    const httpParams = new HttpParams({ fromObject: {queryDataCollection: queryDataCollection} });

    return this.http.get(
      `${this.serviceUrl}${datasetId}/${entity}/transitions`,
      { params: httpParams }
    ).toPromise();
  }

  getDataUpdateInfo(): Promise<{ deploydate: Date, wosextractdate: Date }> {
    return this.http.get<{ deploydate: string, wosextractdate: string }>(
      `${baseUrl}about/data/display/wosanddeploydate`
    )
    .pipe(
      map(({ deploydate, wosextractdate }): { deploydate: Date, wosextractdate: Date }  => ({
        deploydate: this.adjustTimezone(deploydate),
        wosextractdate: this.adjustTimezone(wosextractdate)
      })),
      shareReplay()
    )
    .toPromise();
  }

  getDrilldownMetadata(url: string, name: string, request?: {}) {
    if (name) {
      url += `${url.includes('?') ? '&' : '?'}dataColumn=${name}`;
    }

    if (utilities.isDefined(request)) {
      return this.http.post(url, omit(request, ['pinned', 'citedOrCiting', 'isSourceYear']));
    }

    return this.http.get(url);
  }

  getTileDrilldownMetadata(reportId: string, tileId: string): Promise<any> { // tslint:disable-line:no-any
    const url = `${baseUrl}reports/${reportId}/tiles/${tileId}/drilldown`;

    return this.http.get<any>(`${url}/meta`) // tslint:disable-line:no-any
      .pipe(
        map(data => {
          data.url = `${url}/data`;
          return data;
        })
      )
      .toPromise();
  }

  getUts(url: string, queryParams: object, request: object): Observable<string> {
    // @ts-ignore
    const httpParams = {params: new HttpParams({fromObject: queryParams })};

    return this.http.post<{ itemsList: string[] }>(url, request, httpParams)
      .pipe(
        map(({ itemsList = [] }) => itemsList.join(','))
      );
  }

  getforeignSId(): Observable<string> {
    const url = `${baseUrl}authentication/foreignSID/WOK`;
    return this.http.get<string>(url);
  }

  postWosSearch(uts: String[]): Observable<string> {
    // @ts-ignore
    const request  = {
      uts: uts,
    };

    const httpOptions: object = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'text'
    };

    const url = `${baseUrl}redirect/wosnew`;
    return this.http.post<string>(url, request, httpOptions);
  }

  postWosDerwentSearch(uts: String[]): Observable<string> {
    // @ts-ignore
    const request  = {
      uts: uts,
    };

    const httpOptions: object = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'text'
    };

    const url = `${baseUrl}redirect/wosderwent`;
    return this.http.post<string>(url, request, httpOptions);
  }

  transitions(datasetId: string, entity: string, queryDataCollection: string): Observable<Object> {
    const httpParams = new HttpParams({ fromObject: {queryDataCollection: queryDataCollection} });

    return this.http.get(
      `${this.serviceUrl}${datasetId}/${entity}/transitions`,
      { params: httpParams }
    );
  }
  transitionsForTimeCitedAndNonSelfTimescited(datasetId: string, entity: string, queryDataCollection: string, dataColumn: string): Observable<Object> {
    const httpParams = new HttpParams({ fromObject: { queryDataCollection: queryDataCollection } });

    return this.http.get(
      `${this.serviceUrl}${datasetId}/${entity}/${dataColumn}/transitions`,
      { params: httpParams }
    );
  }
  openWosModal() {
    this.$openWosModal.next();
  }

  private adjustTimezone(receivedDate: string): Date {
    const date = new Date(receivedDate);
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  }
}