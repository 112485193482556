import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from 'components/auth/guards/auth.guard';

let subRoutes = [{
    path : 'landing',
    loadChildren: () => import('../../pages/analytics-landing/analytics-landing.module').then(result => result.AnalyticsLandingModule),
    canActivate: [AuthGuard],
    data : {
      name: 'app.landing',
    }
  },
  {
    path : 'analysis/:datasetId/:entityId',
    loadChildren: () => import('../../pages/analysis/analysis.module').then(result => result.AnalysisModule),
    canActivate: [AuthGuard],
    data : {
      name: 'app.analysis',
      disableInProd: false
    }
  },
  {
    path: 'my-saved',
    loadChildren: () => import('../../pages/my-saved/my-saved.module').then(result => result.MySavedModule),
    canActivate: [AuthGuard],
    data : {
      name: 'app.my-saved',
      disableInProd: false
    }
  },
  {
    path: 'report',
    loadChildren: () => import('../../pages/report/report.module').then(result => result.ReportModule),
    canActivate: [AuthGuard],
    data : {
      name: 'app.reports',
      disableInProd: false
    }
  },
  {
    path: 'tabreports/:tabReportId',
    loadChildren: () => import('../../pages/tab-report/tab-report.module').then(result => result.TabReportModule),
    canActivate: [AuthGuard],
    data : {
      name: 'app.tabreport',
      disableInProd: false
    }
  },
  {
    path: 'report-drill-down/:reportId/:tileId/:slug/:group',
    loadChildren: () => import('../../pages/report-drill-down/report-drill-down.module').then(result => result.ReportDrillDownModule),
    canActivate: [AuthGuard],
    data : {
      name: 'app.report-drill-down',
      disableInProd: false
    }
  },
  {
    path: 'analysis',
    loadChildren: () => import('../../pages/analysis-landing/analysis-landing.module').then(result => result.AnalysisLandingModule),
    canActivate: [AuthGuard],
    data : {
      name: 'app.analysis-landing',
      disableInProd: false
    }
  },
  {
    path : 'reports',
    loadChildren: () => import('../../pages/reports-landing/reports-landing.module').then(result => result.ReportsLandingModule),
    canActivate: [AuthGuard],
    data : {
      name: 'app.reports-landing',
      disableInProd: false
    }
  }
];

const routes: Routes = [

  {
    path: '', redirectTo: '/landing', pathMatch: 'full'
  },
  {
    path : 'auth',
    loadChildren: () => import('../../components/auth/auth.module').then(result => result.AuthModule)
  },
  ...subRoutes,
  {
    path: ':lang',
    children: subRoutes
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }